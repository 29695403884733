// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".fullWidthImage-18w-S{padding:0;margin:0 -15px 15px;max-width:1440px}@media screen and (min-width: 600px){.fullWidthImage-18w-S{padding:0 5%;margin:45px auto}}@media print{.fullWidthImage-18w-S{display:none}}.imageElement-RKg9p{margin:0 auto}.meta-bbwxF{padding-top:10px;text-align:center;font-size:.71429em;line-height:1;margin:0 10px}@media screen and (min-width: 600px){.meta-bbwxF{margin:0}}.caption-3wKnm{font-weight:700}.credit-2kU8U{padding-left:5px}.caption-3wKnm,.credit-2kU8U{display:inline}\n", ""]);
// Exports
exports.locals = {
	"fullWidthImage": "fullWidthImage-18w-S",
	"imageElement": "imageElement-RKg9p",
	"meta": "meta-bbwxF",
	"caption": "caption-3wKnm",
	"credit": "credit-2kU8U"
};
module.exports = exports;
