import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogBody, DialogTitle } from '@blendle/lego';
import { translate, translateElement } from 'instances/i18n';
import Analytics from 'instances/analytics';
import { has, prop, ifElse, pipe, divide, __, always } from 'ramda';

const SECONDS_IN_A_DAY = 60 * 60 * 24;
const DEFAULT_DAY_LIMIT = 30;

const hasAcquirableSeconds = has('seconds');
const acquirableSeconds = prop('seconds');
const secondsToDays = divide(__, SECONDS_IN_A_DAY);
const convertAcquirableSecondsToDays = pipe(acquirableSeconds, secondsToDays);
const determineDayLimit = ifElse(
  hasAcquirableSeconds,
  convertAcquirableSecondsToDays,
  always(DEFAULT_DAY_LIMIT),
);

class ItemNotAcquirable extends PureComponent {
  static propTypes = {
    onDismiss: PropTypes.func.isRequired,
    providerName: PropTypes.string.isRequired,
    providerId: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    acquirable: PropTypes.shape({
      seconds: PropTypes.number,
    }),
  };

  static defaultProps = {
    acquirable: {},
  };

  componentDidMount() {
    const { providerId, itemId } = this.props;

    Analytics.track('Item Not Acquirable', {
      providerId,
      itemId,
    });
  }

  render() {
    const { providerName, acquirable, onDismiss } = this.props;
    const dayLimit = determineDayLimit(acquirable);
    const message = translateElement(<p />, 'item.errors.not_aquirable.message', [
      providerName,
      dayLimit,
      dayLimit,
    ]);

    return (
      <Dialog onClose={onDismiss} data-testid="not-acquirable">
        <DialogTitle>{translate('item.errors.not_aquirable.title')}</DialogTitle>
        <DialogBody>
          {message}
          <Button className="btn-dismiss" onClick={onDismiss}>
            {translate('app.buttons.i_get_it')}
          </Button>
        </DialogBody>
      </Dialog>
    );
  }
}

export default ItemNotAcquirable;
