import { string } from 'prop-types';
import { setPropTypes, compose, branch, renderNothing } from '@blendle/recompose';
import withAudioTrackId from 'higher-order-components/withAudioTrackId';
import renderNothingIfCapabilityIsLocked from 'higher-order-components/renderNothingIfCapabilityIsLocked';
import { FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST } from 'app-constants';
import AudioBanner from '../components/AudioBanner';

const enhance = compose(
  setPropTypes({ itemId: string.isRequired }),
  renderNothingIfCapabilityIsLocked(FEATURE_CAPABILITY_HAS_AUDIO_RECOMMENDATION_PLAYLIST),
  withAudioTrackId,
  branch(({ audioTrackId }) => !audioTrackId, renderNothing),
);

export default enhance(AudioBanner);
