// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-1mGh1{width:40px;height:40px;transform-origin:50% 50%;animation:container-1mGh1 1s forwards}.backdrop-FobqE{transform-origin:50% 50%;animation:backdrop-FobqE 0.5s forwards}.arrows-1KSUI>path{transform-origin:50% 50%;animation:arrows-1KSUI 0.4s forwards}@keyframes container-1mGh1{from{transform:scale(1)}65%{transform:scale(1)}75%{transform:scale(1.2);opacity:1}to{transform:scale(0);opacity:0}}@keyframes backdrop-FobqE{from{transform:scale(0)}55%{transform:scale(1)}75%{transform:scale(0.95)}to{transform:scale(0.98)}}@keyframes arrows-1KSUI{from{transform:translate3d(-100%, 0, 0)}to{transform:translate3d(0, 0, 0)}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1mGh1",
	"backdrop": "backdrop-FobqE",
	"arrows": "arrows-1KSUI"
};
module.exports = exports;
