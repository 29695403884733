import React from 'react';
import Logo from 'components/Logo';
import Link from 'components/Link';
import { translate, translateElement } from 'instances/i18n';
import CSS from './RestrictedProviderError.scss';

const imagePath = '/img/illustrations/crab-dief';

const RestrictedProviderError = () => (
  <section data-testid="app-error-state" className={CSS.container}>
    <div>
      <img
        className={CSS.crab}
        src={`${imagePath}@2x.png`}
        srcSet={`${imagePath}.png 1x, ${imagePath}@2x.png 2x`}
        alt="Error illustration"
      />
    </div>
    <h1 className={CSS.title}>{translate('item.restricted_provider_error.title')}</h1>
    <h2 className={CSS.subTitle}>{translate('item.restricted_provider_error.sub_title')}</h2>
    {translateElement(<p className={CSS.body} />, 'item.restricted_provider_error.message', false)}
    <Link href="/">
      <Logo className={CSS.logo} />
    </Link>
  </section>
);

export default RestrictedProviderError;
