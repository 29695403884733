import altConnect from 'higher-order-components/altConnect';
import { compose, withHandlers } from '@blendle/recompose';
import renderNothingIfIsHidden from 'higher-order-components/renderNothingIfIsHidden';
import { isFollowingEntity } from 'selectors/entity';
import EntityActions from 'actions/EntityActions';
import EntityStore from 'stores/EntityStore';
import ItemStore from 'stores/ItemStore';
import AuthStore from 'stores/AuthStore';
import EntityChip from 'components/EntityChip';
import { INTERNAL_LOCATION } from 'app-constants';

export function mapStateToProps({ entityState, itemState }, { entityId }) {
  const entity = itemState.entities.find((itemEntity) => itemEntity.name === entityId);

  if (!entity) {
    return { isHidden: true };
  }

  return {
    label: entity.title,
    isChecked: isFollowingEntity(entityState.userEntities, entityId),
  };
}

mapStateToProps.stores = { EntityStore, ItemStore };

const enhance = compose(
  renderNothingIfIsHidden,
  altConnect(mapStateToProps),
  withHandlers({
    onClick: ({ entityId, label }) => () => {
      const { userEntities } = EntityStore.getState();
      const { user } = AuthStore.getState();
      const { selectedItemId } = ItemStore.getState();

      const payload = {
        internal_location: INTERNAL_LOCATION.ITEM,
        entity_label: label,
        entity_id: entityId,
        item_id: selectedItemId,
      };

      if (isFollowingEntity(userEntities, entityId)) {
        EntityActions.unfollowEntity(user.id, entityId, payload);
      } else {
        EntityActions.followEntity(user.id, entityId, payload);
      }
    },
  }),
);

export default enhance(EntityChip);
