import { PARTNER_SHARERS } from 'app-constants';
import isDeeplink from '../isDeeplink';

export const getSocialOrigin = (itemId, sharerQueryParam) => {
  if (sharerQueryParam && isDeeplink(itemId)) {
    return sharerQueryParam;
  }

  if (PARTNER_SHARERS.includes(sharerQueryParam)) {
    return sharerQueryParam;
  }

  return null;
};
