import React from 'react';
import { func, number } from 'prop-types';
import formatCurrency from 'helpers/formatCurrency';
import { Notification, NotificationTitle, NotificationBody } from '@blendle/lego';
import useHasCreditsCapability from 'hooks/useHasCreditsCapability';
import { translate } from 'instances/i18n';

const ItemNotFreeForPremiumUserNotification = ({ price, onClick, onMouseEnter, onMouseLeave }) => {
  const hasCreditsCapability = useHasCreditsCapability();

  return (
    <Notification
      className="item-not-in-bundle"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <NotificationTitle>
        {translate('item.purchase_notification.title', [
          formatCurrency(price, { amountIsInCents: true }),
        ])}
      </NotificationTitle>
      <NotificationBody>
        {hasCreditsCapability
          ? translate('item.purchase_notification.text_credits')
          : translate('item.purchase_notification.text_micropayment')}
      </NotificationBody>
    </Notification>
  );
};

ItemNotFreeForPremiumUserNotification.propTypes = {
  onClick: func.isRequired,
  onMouseEnter: func.isRequired,
  onMouseLeave: func.isRequired,
  price: number.isRequired,
};

export default ItemNotFreeForPremiumUserNotification;
