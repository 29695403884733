import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ShareContainer from '../../containers/ShareContainer';
import RefundItemContainer from '../../containers/RefundItemContainer';
import FeedbackContainer from '../../containers/FeedbackContainer';
import SuggestionSectionsContainer from '../../containers/SuggestionSectionsContainer';
import EntitiesContainer from '../../containers/EntitiesContainer';
import CSS from './style.scss';
import ShareForFreeContainer from '../../containers/ShareForFreeContainer';

const ArticleFooter = memo(({ itemId }) => (
  <div>
    <ShareForFreeContainer itemId={itemId} />
    <EntitiesContainer />
    <div className={CSS.articleFooter}>
      <ShareContainer />
      <div className={CSS.itemExtras}>
        <RefundItemContainer itemId={itemId} />
        <FeedbackContainer itemId={itemId} />
      </div>
    </div>
    <SuggestionSectionsContainer itemId={itemId} />
  </div>
));

ArticleFooter.propTypes = {
  itemId: PropTypes.string.isRequired,
};

export default ArticleFooter;
