import useAlt from 'hooks/useAlt';
import { history } from 'byebye';
import ModuleNavigationStore from 'stores/ModuleNavigationStore';
import { removeTrailingSlash, replaceLastPath } from 'helpers/url';
import { omit, isEmpty, prop } from 'ramda';

const getInternalLocation = prop('internal_location');

export const useInternalLocation = () => {
  const analytics = useAlt([ModuleNavigationStore], ([state]) => state.analytics);

  const pathWithoutBuyWarningChildRoute = removeTrailingSlash(history.fragment).replace(
    '/buy-warning',
    '',
  );

  return getInternalLocation(analytics[pathWithoutBuyWarningChildRoute]);
};

export const getAcquireItemPath = location => {
  const searchQueryWithoutCreditsTopupParams = omit(
    ['didCreditsTopup', 'numberOfCredits'],
    location.query,
  );

  const queryString = Object.keys(searchQueryWithoutCreditsTopupParams)
    .map(key => `${key}=${encodeURIComponent(searchQueryWithoutCreditsTopupParams[key])}`)
    .join('&');

  const acquirePath = replaceLastPath(location.pathname, 'acquire');

  return isEmpty(queryString) ? acquirePath : `${acquirePath}?${queryString}`;
};
