// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".refundItem-3qt0t{text-decoration:none;cursor:default;line-height:16px;color:#000;font-size:12px;font-family:\"GT-Walsheim\",sans-serif;display:block;margin:0 auto}.refundItem-3qt0t a{color:#ff1060}.refundItem-3qt0t a:before{content:'';display:block}@media screen and (min-width: 600px){.refundItem-3qt0t{margin:0;text-align:left}.refundItem-3qt0t a:before{content:none}}.centered-3emMi{margin-top:2em}\n", ""]);
// Exports
exports.locals = {
	"refundItem": "refundItem-3qt0t",
	"centered": "centered-3emMi"
};
module.exports = exports;
