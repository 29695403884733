import React from 'react';
import PropTypes from 'prop-types';
import { smartCropImage } from 'helpers/smartCrop';
import classNames from 'classnames';
import ZoomableImageContainer from 'modules/item/containers/ZoomableImageContainer';
import CSS from './style.scss';

function SmartCroppedImage({ image, cropOptions, className, ...props }) {
  const originalImage = image.sizes.original;
  const href = smartCropImage(originalImage, cropOptions);

  const allowZoom = cropOptions.width < Math.min(originalImage.width, window.innerWidth);

  const imgClasses = classNames(className, {
    [CSS.zoom]: allowZoom,
  });

  return (
    <ZoomableImageContainer image={image} active={allowZoom}>
      <img className={imgClasses} src={href} {...props} />
    </ZoomableImageContainer>
  );
}

SmartCroppedImage.propTypes = {
  image: PropTypes.object.isRequired,
  className: PropTypes.string,
  cropOptions: PropTypes.object,
};

export default SmartCroppedImage;
