// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-3Z53E{display:flex;align-items:center}.itemToSpeechButton-35QE5{width:55px;flex-shrink:0;color:#948784;cursor:pointer}@media screen and (min-width: 600px){.itemToSpeechButton-35QE5:hover{color:#333}}.error-1eWSf{opacity:0.4;pointer-events:none}.icon-1Tvj2{position:relative;top:2px;width:20px;height:20px}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-3Z53E",
	"itemToSpeechButton": "itemToSpeechButton-35QE5",
	"error": "error-1eWSf",
	"icon": "icon-1Tvj2"
};
module.exports = exports;
