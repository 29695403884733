import React from 'react';
import { withHandlers } from '@blendle/recompose';
import { oneOf, string } from 'prop-types';
import classNames from 'classnames';
import FacebookIcon from 'components/icons/Facebook';
import TwitterIcon from 'components/icons/Twitter';
import LinkedInIcon from 'components/icons/LinkedIn';
import WhatsAppIcon from 'components/icons/WhatsApp';
import EmailIcon from 'components/icons/Envelope';
import { SHARING_PLATFORM } from 'app-constants';
import CSS from './style.scss';

const icons = new Map([
  [SHARING_PLATFORM.FACEBOOK, FacebookIcon],
  [SHARING_PLATFORM.TWITTER, TwitterIcon],
  [SHARING_PLATFORM.WHATSAPP, WhatsAppIcon],
  [SHARING_PLATFORM.EMAIL, EmailIcon],
  [SHARING_PLATFORM.LINKEDIN, LinkedInIcon],
]);

function SharingButton({ platform, className, ...rest }) {
  const Icon = icons.get(platform);

  return (
    <button className={classNames(CSS.shareButton, CSS[platform], className)} {...rest}>
      <Icon className={CSS.icon} />
    </button>
  );
}

SharingButton.propTypes = {
  platform: oneOf(Array.from(icons.keys())).isRequired,
  className: string,
};

const enhance = withHandlers({
  onClick: ({ onClick, platform }) => (event) => onClick(platform, event),
});

export default enhance(SharingButton);
