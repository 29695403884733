// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".closeIcon-DYPwM{z-index:20}.closeIcon-DYPwM path{fill:rgba(255,255,255,0.9)}.overlay-p0Q_i{background-color:rgba(255,255,255,0.9)}.dialog-11mGs{overflow:hidden}@media screen and (min-width: 600px){.dialog-11mGs{height:473px}}.dialog-11mGs *{font-family:\"GT-Walsheim\",sans-serif}.stepsContainer-123Nn{height:100%}.stepsIndicator-3HZPl{margin:5px 0}.body-3-mXz{display:flex;flex-direction:column;justify-content:space-between;margin-top:45px;height:310px}.body-3-mXz h1{font-size:2em}.body-3-mXz b{font-weight:700}.body-3-mXz p{margin:1em 0}\n", ""]);
// Exports
exports.locals = {
	"closeIcon": "closeIcon-DYPwM",
	"overlay": "overlay-p0Q_i",
	"dialog": "dialog-11mGs",
	"stepsContainer": "stepsContainer-123Nn",
	"stepsIndicator": "stepsIndicator-3HZPl",
	"body": "body-3-mXz"
};
module.exports = exports;
