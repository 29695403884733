import { string } from 'prop-types';
import { setPropTypes, compose, branch, renderNothing, lifecycle } from '@blendle/recompose';
import Auth from 'controllers/auth';
import altConnect from 'higher-order-components/altConnect';
import ShareForFree from '../components/ShareForFree';
import ShareForFreeStore from '../stores/ShareForFreeStore';
import ShareForFreeActions from '../actions/ShareForFreeActions';

function mapStateToProps({ shareForFreeState }) {
  return {
    shareToken: shareForFreeState.shareToken,
  };
}

mapStateToProps.stores = { ShareForFreeStore };

const enhance = compose(
  setPropTypes({ itemId: string.isRequired }),
  lifecycle({
    componentDidMount() {
      const userHasPremium = Auth.getUser().hasActivePremiumSubscription();
      const isBrowserSupported = document.queryCommandSupported('copy');

      if (userHasPremium && isBrowserSupported) {
        const { itemId } = this.props;

        ShareForFreeActions.generateShareToken.defer(itemId);
      }
    },
  }),
  altConnect(mapStateToProps),
  branch(({ shareToken }) => !shareToken, renderNothing),
);

export default enhance(ShareForFree);
