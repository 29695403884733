// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".section-3uust{position:relative;width:100%;display:flex;flex-wrap:wrap;align-items:center}@media screen and (max-width: 320px){.section-3uust{flex-direction:column}}.button-1ugSp{cursor:pointer;padding:0 10px 0 0;position:relative}.button-1ugSp .heart-2Y3sG{transition:transform 0.1s ease-out}.button-1ugSp:hover .heart-2Y3sG{transform:scale(1.1)}.button-1ugSp:active .heart-2Y3sG{transform:scale(0.95)}.heart-2Y3sG{width:35px}.buttonActive-1NpU_ .heart-2Y3sG{animation:heart-bounce-2nj4w 0.3s ease-out 0s}.buttonActive-1NpU_:hover .heart-2Y3sG{transform:scale(1.1)}.buttonActive-1NpU_:active .heart-2Y3sG{transform:scale(0.95)}.particle-36pM8{position:absolute;transform:scale(0.6);top:0;left:6px;transition:left 1.3s, top 1.3s;transition-timing-function:cubic-bezier(0.58, 1.35, 0.68, 1.02);opacity:0}.particleFade-2Ia1n{animation:fade-in-out-uq4AO 1.3s ease}.likesCountText-3kBxx{display:inline-block;align-items:center;font-size:14px;color:#ff0e61;background:linear-gradient(45deg, #ff0e61 75%, #ff6255);-webkit-background-clip:text;-webkit-text-fill-color:transparent}@keyframes fade-in-out-uq4AO{0%{opacity:0}20%{opacity:1}60%{opacity:1}100%{opacity:0}}@keyframes heart-bounce-2nj4w{0%{transform:scale(0.9)}50%{transform:scale(1.25)}90%{transform:scale(0.95)}100%{transform:scale(1.1)}}\n", ""]);
// Exports
exports.locals = {
	"section": "section-3uust",
	"button": "button-1ugSp",
	"heart": "heart-2Y3sG",
	"buttonActive": "buttonActive-1NpU_",
	"heart-bounce": "heart-bounce-2nj4w",
	"particle": "particle-36pM8",
	"particleFade": "particleFade-2Ia1n",
	"fade-in-out": "fade-in-out-uq4AO",
	"likesCountText": "likesCountText-3kBxx"
};
module.exports = exports;
