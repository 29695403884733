import React, { PureComponent } from 'react';
import { bool } from 'prop-types';
import ItemStore from 'stores/ItemStore';
import { throttle } from 'lodash';
import ItemActions from 'actions/ItemActions';

/**
 * @typedef {Object} ElementShownReturn
 * @property {number} viewportHeight
 * @property {number} scrollPixelsFromTop
 * @property {number} readingPercentage
 */

/**
 * @param {HTMLElement} target
 * @returns {ElementShownReturn}
 */
function elementShownPercentage(target) {
  const { top, height } = target.getBoundingClientRect();
  const viewportHeight = window.innerHeight;
  const percent = (100 / height) * (top * -1 + viewportHeight);
  return {
    scrollPixelsFromTop: top * -1,
    readingPercentage: Math.min(Math.round(percent), 100),
    viewportHeight,
  };
}

function visibleParagraphQuotient(paragraphTopPositions, scrollY) {
  if (paragraphTopPositions.length === 0) {
    return 0;
  }

  let foundIndex = 0;
  for (let i = paragraphTopPositions.length - 1; i >= 0; i--) {
    const topPos = paragraphTopPositions[i];
    if (scrollY > topPos) {
      foundIndex = i;
      break;
    }
  }

  const quotient = foundIndex / paragraphTopPositions.length;
  return quotient;
}

export default ComposedComponent =>
  class WithElementShownPercentage extends PureComponent {
    static propTypes = {
      isContentReady: bool.isRequired,
    };

    componentWillUnmount() {
      this.throttledHandleScroll.cancel();
      this.removeEventListener();
    }

    removeEventListener = () => {
      window.removeEventListener('scroll', this.throttledHandleScroll);
    };

    handleScroll = () => {
      const { isContentReady } = this.props;

      if (!isContentReady) {
        return;
      }

      const { paragraphPositions } = ItemStore.getState();

      const { scrollPixelsFromTop, readingPercentage, viewportHeight } = elementShownPercentage(
        this.percentageElement,
      );
      ItemActions.scrollItem.defer({
        readingPercentage,
        scrollPixelsFromTop,
        currentParagraphQuotient: visibleParagraphQuotient(paragraphPositions, scrollPixelsFromTop),
        viewportHeight,
      });
    };

    // eslint-disable-next-line react/sort-comp
    throttledHandleScroll = throttle(this.handleScroll, 1000 / 5);

    setupScrollListeners = element => {
      this.removeEventListener();
      if (element) {
        this.percentageElement = element;
        window.addEventListener('scroll', this.throttledHandleScroll, {
          passive: true,
        });
        this.throttledHandleScroll();
      }
    };

    render() {
      return (
        <div ref={this.setupScrollListeners}>
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  };
