import React from 'react';
import { prop, path, equals, compose, anyPass, startsWith, endsWith, isNil, not } from 'ramda';
import {
  branch,
  renderComponent,
  withProps,
  mapProps,
  withState,
  onlyUpdateForKeys,
  setDisplayName,
} from '@blendle/recompose';
import ItemStore from 'stores/ItemStore';
import TilesStore from 'stores/TilesStore';
import AuthStore from 'stores/AuthStore';
import Analytics from 'instances/analytics';
import PaymentActions from 'actions/PaymentActions';
import ItemActions from 'actions/ItemActions';
import history from 'libs/byebye/history';
import { prefillSelector, providerById } from 'selectors/providers';
import { removeTrailingSlash } from 'helpers/url';
import altConnect from 'higher-order-components/altConnect';
import redirect from 'higher-order-components/redirect';
import tap from 'higher-order-components/tap';
import { hasCapability } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { FEATURE_CAPABILITY_BLENDLE_PREMIUM } from 'app-constants';
import ItemNotFound from '../components/ItemNotFound';
import LoadFailedRetry from '../components/LoadFailedRetry';
import ConfirmAccountContainer from '../containers/ConfirmAccountContainer';
import loadItem from '../helpers/loadItem';
import ItemNotAcquirable from '../components/ItemNotAcquirable';
import RestrictedProviderError from '../components/RestrictedProviderError';

const isPaymentRequiredError = compose(
  equals('PaymentRequired'),
  path(['data', '_errors', 0, 'id']),
);

function handleDismiss() {
  const { returnUrl } = ItemStore.getState();
  history.navigate(returnUrl, { trigger: true, replace: true });
}

function handleRetry() {
  const { selectedItemId } = ItemStore.getState();
  loadItem(selectedItemId);
}

const httpStatus = prop('status');
const acquired = prop('acquired');

const isPaymentRequired = equals(402);
const isPaymentRequiredStatus = compose(isPaymentRequired, httpStatus);
const isForbidden = equals(403);
const isForbiddenStatus = compose(isForbidden, httpStatus);
const isNotFound = equals(404);
const isNotFoundStatus = compose(isNotFound, httpStatus);
const isUnprocessableEntity = equals(422);
const isUnprocessableEntityStatus = compose(isUnprocessableEntity, httpStatus);
const isUnavailableForLegalReasons = equals(451);
const isUnavailableForLegalReasonsStatus = compose(isUnavailableForLegalReasons, httpStatus);

const isPaymentProcessPath = anyPass([
  endsWith('/buy-warning'),
  endsWith('/acquire'),
  endsWith('/refund'),
  startsWith('/payment'),
  startsWith('/premium-intro'),
]);

const isReadingPreferencesPath = anyPass([
  endsWith('/preferences/channels'),
  endsWith('/preferences/publications'),
]);

const getTopUpUrl = (hasBlendlePremium) =>
  hasBlendlePremium ? '/payment/outofbalance' : '/payment';

const isLoggedIn = compose(not, isNil);

const renderWithDismissHandler = compose(
  renderComponent,
  withProps({ onDismiss: handleDismiss, onRetry: handleRetry }),
);

const replaceWhenItemNotFound = compose(
  setDisplayName('ReplaceWhenItemNotFound'),
  branch(({ error }) => isNotFoundStatus(error), renderWithDismissHandler(ItemNotFound)),
);

const replaceWhenAccountUnconfirmed = compose(
  setDisplayName('ReplaceWhenAccountUnconfirmed'),
  branch(
    ({ acquisitionError }) => isForbiddenStatus(acquisitionError),
    renderWithDismissHandler(ConfirmAccountContainer),
  ),
);

const replaceItemNotAcquirable = compose(
  setDisplayName('ReplaceItemNotAcquirable'),
  branch(
    ({ acquisitionError }) => isUnprocessableEntityStatus(acquisitionError),
    renderComponent(
      ({ provider, selectedItemId }) =>
        provider && (
          <ItemNotAcquirable
            providerName={provider.name}
            providerId={provider.id}
            acquirable={provider.acquirable}
            itemId={selectedItemId}
            onDismiss={handleDismiss}
          />
        ),
    ),
  ),
);

const replaceGeneralError = compose(
  setDisplayName('ReplaceGeneralError'),
  branch(
    ({ error }) => error && !isPaymentRequiredStatus(error),
    renderWithDismissHandler(LoadFailedRetry),
  ),
);

const replaceBlacklistedProviderNotification = compose(
  setDisplayName('ReplaceBlacklistedProviderNotification'),
  branch(
    ({ acquisitionError }) => isUnavailableForLegalReasonsStatus(acquisitionError),
    renderComponent(RestrictedProviderError),
  ),
);

const redirectToBuyWarningIfNeeded = compose(
  setDisplayName('RedirectToBuyWarningIfNeeded'),
  branch(
    ({ error, acquisition, pathname }) =>
      isPaymentRequiredStatus(error) &&
      !acquired(acquisition) && // make sure the item is not acquired yet
      isPaymentRequiredError(error) &&
      !isPaymentProcessPath(pathname) &&
      !isReadingPreferencesPath(pathname),
    compose(
      mapProps(({ pathname }) => ({
        redirectTo: `${pathname}/buy-warning${window.location.search}`,
      })),
      redirect({ replace: true, renderNothing: true }),
    ),
  ),
);

const sendAnalyticsAndSetReturnUrl = tap(({ selectedItemId, setBalanceToLowId, pathname }) => {
  setBalanceToLowId(selectedItemId);

  Analytics.track('Balance too low');

  // Use hard redirect because the AcquireItemContainer stays mounted
  PaymentActions.setReturnUrl.defer(pathname, true);

  ItemActions.consumeErrors.defer();
});

const redirectWhenBalanceIsToLow = compose(
  setDisplayName('RedirectWhenBalanceIsToLow'),
  withState('balanceToLowId', 'setBalanceToLowId', null),
  branch(
    ({ acquisitionError, balanceToLowId, selectedItemId, topUpUrl }) =>
      isPaymentRequiredStatus(acquisitionError) && balanceToLowId !== selectedItemId && topUpUrl,
    compose(
      sendAnalyticsAndSetReturnUrl,
      mapProps(({ pathname, topUpUrl, returnUrl }) => ({
        redirectTo: {
          pathname: topUpUrl,
          state: {
            returnUrl: returnUrl || '/',
            successUrl: pathname,
          },
        },
      })),
      onlyUpdateForKeys(['redirectTo']),
      redirect({ replace: true, renderNothing: true }),
    ),
  ),
);

function mapStateToProps({ itemState, tilesState, authState, featureCapabilitiesState }) {
  const { user } = authState;
  const { error, acquisitionError, selectedItemId, returnUrl } = itemState;
  const { tiles, acquisition } = tilesState;

  const tile = tiles.get(selectedItemId);
  const provider = tile
    ? prefillSelector(providerById)(tile._embedded['b:manifest'].provider.id)
    : null;

  const topUpUrl =
    tile && user
      ? getTopUpUrl(hasCapability(featureCapabilitiesState, FEATURE_CAPABILITY_BLENDLE_PREMIUM))
      : undefined;
  const pathname = removeTrailingSlash(window.location.pathname);

  return {
    selectedItemId,
    returnUrl,
    error,
    acquisitionError,
    acquisition,
    provider,
    isLoggedIn: isLoggedIn(user),
    pathname,
    topUpUrl,
  };
}
mapStateToProps.stores = {
  ItemStore,
  TilesStore,
  AuthStore,
  FeatureCapabilitiesStore,
};

export const replaceAndRedirects = compose(
  replaceItemNotAcquirable,
  replaceWhenItemNotFound,
  replaceWhenAccountUnconfirmed,
  replaceGeneralError,
  replaceBlacklistedProviderNotification,
  redirectToBuyWarningIfNeeded,
  redirectWhenBalanceIsToLow,
);

export default compose(
  altConnect(mapStateToProps),
  branch(prop('isLoggedIn'), replaceAndRedirects),
);
