import React, { PureComponent } from 'react';
import { get } from 'lodash';
import ItemStore from 'stores/ItemStore';
import TilesStore from 'stores/TilesStore';
import IssueAcquisitionStore from 'stores/IssueAcquisitionStore';
import IssueAcquisitionActions from 'actions/IssueAcquisitionActions';
import { getTileManifest } from 'selectors/tiles';
import { FEATURE_CAPABILITY_MICROPAYMENTS } from 'app-constants';
import { hasCapability } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';

function payedForItem(acquisition) {
  return get(acquisition, 'purchase_origin') === 'money';
}

function handleAutoPurchase(
  itemState,
  tilesState,
  issueAcquisitionState,
  featureCapabilitiesState,
) {
  const hasMicropayments = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_MICROPAYMENTS,
  );

  const { autoRefundable, acquisition, selectedItemId, error } = itemState;

  if (error || !hasMicropayments) {
    return;
  }

  const { tiles } = tilesState;
  const tile = tiles.get(selectedItemId);
  const manifest = getTileManifest(tile);
  const issueId = get(manifest, 'issue.id');
  const issueAcquisition = get(issueAcquisitionState, `issueAcquisitions.[${issueId}]`);

  if (
    issueId &&
    !autoRefundable &&
    payedForItem(acquisition) &&
    get(issueAcquisition, 'acquirable') &&
    get(issueAcquisition, 'price') <= 0
  ) {
    IssueAcquisitionActions.acquireIssue(issueId, manifest.provider.id, 'issue');
  }
}

export default ComposedComponent =>
  class autoPurchaseIssue extends PureComponent {
    fetched = undefined;

    componentDidMount() {
      TilesStore.listen(this.fetchIssueAcquistion);
      ItemStore.listen(this.fetchIssueAcquistion);
    }

    componentWillUnmount() {
      TilesStore.unlisten(this.fetchIssueAcquistion);
      ItemStore.unlisten(this.fetchIssueAcquistion);

      handleAutoPurchase(
        ItemStore.getState(),
        TilesStore.getState(),
        IssueAcquisitionStore.getState(),
        FeatureCapabilitiesStore.getState(),
      );
    }

    fetchIssueAcquistion = () => {
      const hasMicropayments = hasCapability(
        FeatureCapabilitiesStore.getState(),
        FEATURE_CAPABILITY_MICROPAYMENTS,
      );
      const { tiles } = TilesStore.getState();
      const { selectedItemId, acquisition } = ItemStore.getState();
      const tile = tiles.get(selectedItemId);

      if (
        !!tile &&
        payedForItem(acquisition) &&
        this.fetched !== selectedItemId &&
        hasMicropayments
      ) {
        this.fetched = selectedItemId;

        const manifest = getTileManifest(tile);
        const issueId = get(manifest, 'issue.id');
        if (issueId) {
          IssueAcquisitionActions.fetchIssueAcquistion.defer(issueId);
        }
      }
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };
