import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { getReadingTime } from 'helpers/manifest';
import { translate } from 'instances/i18n';
import CSS from './style.scss';

class ItemMeta extends PureComponent {
  static propTypes = {
    date: PropTypes.string,
    length: PropTypes.shape({
      words: PropTypes.number.isRequired,
    }),
    className: PropTypes.string,
  };

  static defaultProps = {
    date: null,
    length: null,
    className: '',
  };

  render() {
    const { date, length, className } = this.props;
    if (!date || !length) {
      return null;
    }

    const momentDate = moment(date);
    const readingTime = moment.duration(getReadingTime(length), 'minutes').humanize();

    return (
      <div className={classNames(CSS.itemMeta, className)}>
        <p className={CSS.printNotice}>
          {translate('print_notice')} <br />
          {momentDate.format('L')}
        </p>
        <time className={CSS.time} dateTime={momentDate.toISOString()}>
          {`${momentDate.calendar()} · ${readingTime} ${translate(
            'app.manifest.time.to.read_reader',
          )}`}
        </time>
      </div>
    );
  }
}

export default ItemMeta;
