import React from 'react';
import PropTypes from 'prop-types';
import ProviderImage from 'components/ProviderImage';
import { getDefaultTileTemplate } from 'helpers/providerHelpers';
import { renderItemContent } from 'helpers/renderItemContent';
import { getManifestBody } from 'helpers/manifest';

function DeeplinkManifest(props) {
  const { manifest } = props;
  const { provider } = manifest;

  const itemContent = renderItemContent(
    getManifestBody(manifest),
    getDefaultTileTemplate(provider.id),
  );

  return (
    <div className={`item item-content provider-${provider.id}`}>
      <ProviderImage className="item-provider" provider={provider} />
      {itemContent}
    </div>
  );
}

DeeplinkManifest.propTypes = {
  manifest: PropTypes.object.isRequired,
};

export default DeeplinkManifest;
