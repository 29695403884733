// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zoom-2ddSS{cursor:zoom-in}\n", ""]);
// Exports
exports.locals = {
	"zoom": "zoom-2ddSS"
};
module.exports = exports;
