import React, { useEffect } from 'react';
import { Dialog, Button, DialogBody } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import { translate, translateElement } from 'instances/i18n';
import useDidDismissNewCreditKioskDialog from 'hooks/useDidDismissNewCreditKioskDialog';
import { INTERNAL_LOCATION, DEFAULT_CREDITS_BUNDLE } from 'app-constants';
import Analytics from 'instances/analytics';
import { string } from 'prop-types';
import CSS from './styles.scss';

const Title = styledModule.h1(CSS.title);
const Paragraph = styledModule.p(CSS.paragraph);
const Cta = styledModule(Button)(CSS.cta);

const CreditsAnnouncementDialog = ({ itemId }) => {
  const { showDialog, dismissDialog } = useDidDismissNewCreditKioskDialog();
  const { amount, formattedPrice } = DEFAULT_CREDITS_BUNDLE;

  useEffect(() => {
    if (showDialog) {
      Analytics.track('Credit Explanation Shown', {
        internal_location: INTERNAL_LOCATION.ITEM,
        item_id: itemId,
      });
    }
  }, [showDialog]);

  return (
    <Dialog open={showDialog} onClose={dismissDialog}>
      <DialogBody>
        <Title>{translate('item.credits.announcement.title')}</Title>
        <Paragraph>
          {translateElement('item.credits.announcement.text', [formattedPrice, amount])}
        </Paragraph>
        <Cta color="razzmatazz" onClick={dismissDialog} data-testid="submit-cta">
          {translate('item.credits.announcement.cta')}
        </Cta>
      </DialogBody>
    </Dialog>
  );
};

CreditsAnnouncementDialog.propTypes = {
  itemId: string.isRequired,
};

export default CreditsAnnouncementDialog;
