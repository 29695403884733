import React, { PureComponent } from 'react';
import ItemStore from 'stores/ItemStore';
import TilesStore from 'stores/TilesStore';
import Auth from 'controllers/auth';
import { getTileManifest } from 'selectors/tiles';
import Dialogue from 'components/dialogues/Dialogue';
import Country from 'instances/country';
import Button from 'components/Button';

export default class ItemWarning extends PureComponent {
  constructor(props) {
    super(props);

    const showWarning = !Auth.getUser().getPreference('initial_purchase_message_faz_shown');
    this.state = { showWarning };
  }

  _close = () => {
    this.setState({ showWarning: false });
    Auth.getUser().savePreferences({
      initial_purchase_message_faz_shown: true,
    });
  };

  render() {
    const { selectedItemId } = ItemStore.getState();
    const { tiles } = TilesStore.getState();
    const tile = tiles.get(selectedItemId);

    if (!selectedItemId || !tile) {
      return null;
    }

    const manifest = getTileManifest(tile);

    if (
      this.state.showWarning &&
      Country.getCountryCode() === 'DE' &&
      manifest.provider.id === 'faz'
    ) {
      return (
        <Dialogue onClose={this._close}>
          <h2>Mooooment…</h2>
          <p>
            Mir ist bewusst, dass Blendle ein Dienst ist, der nicht zu kommerziellen Zwecken genutzt
            werden darf.
          </p>
          <p>
            Ich bestätige, dass ich den Dienst ausschließlich zu persönlichen und privaten, nicht
            kommerziellen Zwecken nutze und auch meine Zugangsdaten nicht an Dritte, insbesondere
            zur kommerziellen Nutzung, weitergebe. Hierunter fallen unter anderem auch Behörden,
            Gerichte, Verbände, Archive, Museen, Vereine, Körperschaften des öffentlichen Rechts und
            Stiftungen.
          </p>
          <Button className="btn-fullwidth" onClick={this._close}>
            Nee, klar. Supernett.
          </Button>
        </Dialogue>
      );
    }
    return null;
  }
}
