import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import useAlt from 'hooks/useAlt';
import { hasCapability } from 'selectors/capabilities';
import { FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS } from 'app-constants';

const useHasCreditsCapability = () => {
  const capabilitiesState = useAlt([FeatureCapabilitiesStore], ([state]) => state);

  return hasCapability(capabilitiesState, FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS);
};

export default useHasCreditsCapability;
