import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import { translateElement } from 'instances/i18n';
import formatCurrency from 'helpers/formatCurrency';
import useHasCreditsCapability from 'hooks/useHasCreditsCapability';
import styledModule from 'helpers/styledModule';
import CSS from './style.scss';

const StyledLink = styledModule(Link)(CSS.refundItem);

function RefundItem({ refundLink, price }) {
  const hasCreditsCapability = useHasCreditsCapability();
  const formattedPrice = formatCurrency(price, { amountIsInCents: true });

  return hasCreditsCapability ? (
    <StyledLink href={refundLink} data-testid="refund-credits">
      {translateElement('item.text.refund_credits', [formattedPrice])}
    </StyledLink>
  ) : (
    <StyledLink href={refundLink} data-testid="refund-mp">
      {translateElement('item.text.refund', [formattedPrice])}
    </StyledLink>
  );
}

RefundItem.propTypes = {
  refundLink: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default RefundItem;
