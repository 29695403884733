import React from 'react';
import { object, node, string, func, bool } from 'prop-types';
import withRouter from 'react-router/lib/withRouter';
import Loading from 'components/Loading';
import ItemMetaContainer from '../../containers/ItemMetaContainer';
import ProgressBar from '../ProgressBar';
import TopBarContainer from '../../containers/TopBarContainer';
import StickySharingContainer from '../../containers/StickySharingContainer';
import RestoreReadingProgressContainer from '../../containers/RestoreReadingProgressContainer';
import JustEnoughBalanceContainer from '../../containers/JustEnoughBalanceContainer';
import ImageZoomContainer from '../../containers/ImageZoomContainer';
import SignUpRewardDialogContainer from '../../containers/SignUpRewardDialogContainer';
import ArticleFooterContainer from '../../containers/ArticleFooterContainer';
import ContentBody from '../ContentBody';
import ItemWarning from '../ItemWarning';
import ItemLoading from '../ItemLoading';
import CSS from './styles.scss';

function Reader(props) {
  const { content, dialog, isContentReady, isLoading, itemId, paragraphsMeasured } = props;

  return (
    <div className={CSS.item}>
      <ProgressBar />
      <TopBarContainer />
      <div className={CSS.scrollable} data-testid="scroller">
        <ItemLoading itemId={itemId} isLoading={isLoading} />
        {!isLoading && [
          <ItemMetaContainer key="item-meta" />,
          <ContentBody
            content={content}
            paragraphsMeasured={paragraphsMeasured}
            isContentReady={isContentReady}
            key="ContentBody"
          />,
          !isContentReady && <Loading key="article-content-loading" className={CSS.loadMore} />,
        ]}
        {isContentReady && [
          <RestoreReadingProgressContainer key="restore-reading-progress" />,
          <ArticleFooterContainer key="ArticleFooterContainer" />,
        ]}
      </div>
      {!isLoading && [
        <SignUpRewardDialogContainer key="SignUpRewardDialogContainer" />,
        <StickySharingContainer key="StickySharingContainer" />,
      ]}
      <JustEnoughBalanceContainer />
      <ImageZoomContainer />
      <ItemWarning />
      {dialog}
    </div>
  );
}

Reader.propTypes = {
  content: object, // eslint-disable-line react/forbid-prop-types, react/require-default-props
  dialog: node, // eslint-disable-line react/require-default-props
  itemId: string.isRequired,
  paragraphsMeasured: func.isRequired,
  isLoading: bool.isRequired,
  isContentReady: bool.isRequired,
};

export default withRouter(Reader);
