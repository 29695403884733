import React from 'react';
import PropTypes from 'prop-types';
import { DialogBody, Button, Backdrop } from '@blendle/lego';
import { translate, translateElement } from 'instances/i18n';
import Illustration from '../Illustration';
import CSS from './style.scss';

const PresentContent = ({ onNextStep, className, price, gift }) => (
  <div data-testid="purchase-warning-first">
    <Backdrop className={CSS.backdrop} innerClassName={CSS.backdropInner} />
    <Illustration name="first_article" />
    <DialogBody className={className}>
      <h1>{translate('item.text.purchase_warning_title', [gift])}</h1>
      {translateElement('item.text.purchase_warning_intro', { price, gift })}
      <div>
        <Button onClick={onNextStep} data-testid="purchase-warning-next-btn">
          {translate('supersympathiek')}
        </Button>
      </div>
    </DialogBody>
  </div>
);

PresentContent.propTypes = {
  onNextStep: PropTypes.func.isRequired,
  className: PropTypes.string,
  price: PropTypes.string.isRequired,
  gift: PropTypes.string.isRequired,
};

export default PresentContent;
