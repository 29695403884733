// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-11PQJ{margin:0 16px 45px}.container-2h46h{max-width:375px;margin:0 auto;border-radius:4px;padding:27px 16px}.red-AvJhH{background:rgba(255,14,97,0.05)}.green-1ff0b{background:rgba(0,210,99,0.1)}.title-2X5Du{font-family:\"GT-Walsheim\",sans-serif;color:#5b5b5b;font-size:15px;text-align:center;letter-spacing:-0.02em;line-height:21px}.text-26qPU{font-family:\"GT-Walsheim\",sans-serif;color:#595959;opacity:0.9;font-size:15px;text-align:center;letter-spacing:-0.05em;margin-top:8px;margin-bottom:22px}.inputWrapper-2ALq7{display:flex;justify-content:center;align-items:center;flex-direction:column}@media (min-width: 407px){.inputWrapper-2ALq7{flex-direction:row;justify-content:center;align-items:center}}.input-35R1B{height:38px;width:100%;padding:17px 15px 12px;border-radius:3px;border:0;font-size:15px}@media (min-width: 407px){.input-35R1B{width:191px;padding:17px 0 12px 15px;border-top-right-radius:0;border-bottom-right-radius:0}}.button-N5iFU{width:100%;padding:10px 20px;margin-top:8px}@media (min-width: 407px){.button-N5iFU{margin-top:0;width:151px;border-top-left-radius:0;border-bottom-left-radius:0}}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-11PQJ",
	"container": "container-2h46h",
	"red": "red-AvJhH",
	"green": "green-1ff0b",
	"title": "title-2X5Du",
	"text": "text-26qPU",
	"inputWrapper": "inputWrapper-2ALq7",
	"input": "input-35R1B",
	"button": "button-N5iFU"
};
module.exports = exports;
