// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".articleFooter-2PuvS{max-width:670px;margin:0 auto;padding:0}@media screen and (min-width: 600px){.articleFooter-2PuvS{padding-left:15px;padding-right:15px}}@media print{.articleFooter-2PuvS{display:none}}.itemExtras-23vjh{display:flex;flex-direction:column;padding:15px;text-align:center;justify-content:space-between;border-top:1px solid rgba(0,0,0,0.1)}@media screen and (min-width: 600px){.itemExtras-23vjh{text-align:left;padding:15px 0;flex-direction:row}}\n", ""]);
// Exports
exports.locals = {
	"articleFooter": "articleFooter-2PuvS",
	"itemExtras": "itemExtras-23vjh"
};
module.exports = exports;
