import React from 'react';
import BrowserEnv from 'instances/browser_environment';
import useCookieConsent from 'hooks/useCookieConsent';
import { SHARING_PLATFORM } from 'app-constants';
import DesktopStickySharing from './DesktopStickySharingContainer';
import MobileStickySharingContainer from './MobileStickySharingContainer';

const StickySharingContainer = () => {
  const { hasGivenConsent } = useCookieConsent();

  const socialPlatforms = [
    hasGivenConsent ? SHARING_PLATFORM.FACEBOOK : undefined,
    SHARING_PLATFORM.TWITTER,
    SHARING_PLATFORM.WHATSAPP,
    SHARING_PLATFORM.EMAIL,
    SHARING_PLATFORM.LINKEDIN,
  ].filter(Boolean);

  return BrowserEnv.isMobile() ? (
    <MobileStickySharingContainer
      socialPlatforms={socialPlatforms}
      hideOpenInApp={!BrowserEnv.platformHasApp()}
    />
  ) : (
    <DesktopStickySharing socialPlatforms={['blendle', ...socialPlatforms]} />
  );
};

export default StickySharingContainer;
