import React from 'react';
import { setStatic, wrapDisplayName, withState, compose, withHandlers } from '@blendle/recompose';
import ShareToEmailContainer from 'containers/ShareToEmailContainer';

const withShareToEmail = (ComposedComponent) => {
  const enhance = compose(
    setStatic('displayName', wrapDisplayName(ComposedComponent, 'withShareToEmail')),
    withState('isEmailDialogOpen', 'setEmailDialogOpen', false),
    withHandlers({
      openEmailShareDialog: (props) => () => props.setEmailDialogOpen(true),
      closeEmailShareDialog: (props) => () => props.setEmailDialogOpen(false),
    }),
  );
  return enhance(({ isEmailDialogOpen, closeEmailShareDialog, openEmailShareDialog, ...props }) => (
    <div>
      <ComposedComponent {...props} openEmailShareDialog={openEmailShareDialog} />
      <ShareToEmailContainer
        itemId={props.itemId}
        isVisible={isEmailDialogOpen}
        onClose={closeEmailShareDialog}
        analytics={props.analytics}
      />
    </div>
  ));
};

export default withShareToEmail;
