import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ProviderLogo from 'components/ProviderLogo';
import PinButton from 'components/buttons/PinButton';
import PurchaseNoticeContainer from '../../../containers/PurchaseNoticeContainer';
import CloseButton from '../../CloseButton';
import ItemOptionsDropdownContainer from '../../../containers/ItemOptionsDropdownContainer';
import ItemToSpeechContainer from '../../../containers/ItemToSpeechContainer';
import CSS from './style.scss';

function TopbarContent(props) {
  const { onClose, pinItem, isPinned, provider } = props;

  return (
    <div className={CSS.topbarContent}>
      <CloseButton onClick={onClose} data-testid="reader-close-button" />
      <PinButton className={CSS.pinButton} marked={isPinned} onChange={pinItem} showText={false} />
      <PurchaseNoticeContainer />
      <ProviderLogo provider={provider} className={CSS.providerLogo} />
      <div className={CSS.pullRight}>
        <ItemToSpeechContainer />
        <div className={CSS.optionsDropdown}>
          <ItemOptionsDropdownContainer />
        </div>
      </div>
    </div>
  );
}

TopbarContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  pinItem: PropTypes.func.isRequired,
  isPinned: PropTypes.bool,
  provider: PropTypes.object,
};

TopbarContent.defaultProps = {
  isPinned: false,
  provider: null,
};

export default TopbarContent;
