import { PLAY_STORE_URL } from 'app-constants';

export const getAppItemUrl = itemId => `blendle://item/${itemId}`;

export const openItemInAndroid = itemId => {
  const openInAppWindow = window.open(getAppItemUrl(itemId), '_blank');
  setTimeout(() => {
    if (openInAppWindow) {
      openInAppWindow.location = PLAY_STORE_URL;
    }
  }, 500);
};
