import { useEffect } from 'react';
import { isNil, isEmpty, anyPass, prop } from 'ramda';

import useAlt from 'hooks/useAlt';
import ProviderStyleActions from 'actions/ProviderStyleActions';
import ProviderStyleStore from 'stores/ProviderStyleStore';

const hasNoStyles = anyPass([isNil, isEmpty]);

export function useProviderStyles(providerId) {
  const providers = useAlt([ProviderStyleStore], ([state]) => state.providers);

  const providerStyle = providers[providerId];

  const hasNoProviderStyle = hasNoStyles(providerStyle);

  useEffect(() => {
    if (providerId && hasNoProviderStyle) {
      ProviderStyleActions.fetchProviderStyles(providerId);
    }
  }, [hasNoProviderStyle, providerId]);

  return {
    bgColor: prop('bgColor', providerStyle),
    fontColor: prop('fontColor', providerStyle),
  };
}
