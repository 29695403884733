import React, { memo } from 'react';
import ItemStore from 'stores/ItemStore';
import useAlt from 'hooks/useAlt';
import { isContentReady } from 'modules/item/helpers/readerStatus';
import styledModule from 'helpers/styledModule';
import CSS from './styles.scss';

const Bar = styledModule.div(CSS.progress);

const ProgressBar = memo(() => {
  const { readingPercentage, content } = useAlt([ItemStore], ([state]) => state);

  if (!isContentReady(content)) {
    return null;
  }

  const style = {
    transform: `translateX(${-100 + readingPercentage}%)`,
  };

  return <Bar style={style} />;
});

export default ProgressBar;
