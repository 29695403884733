import React, { PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RecommendHeart from 'components/icons/RecommendHeart';
import CSS from './style.scss';

function generateSVGAttrs(isActive) {
  return isActive ? {} : { fill: '#fff', stroke: '#FF6255' };
}

function getParticleStyle(isActive, index) {
  if (isActive) {
    return { top: 0, left: 6 };
  }

  const delay = (index + 1) / 10;

  return {
    left: Math.ceil(Math.random() * 200 - 100),
    top: Math.ceil(Math.random() * -70 - 200),
    transitionDelay: `${delay}s`,
    animationDelay: `${delay}s`,
  };
}

export default class RecommendButtonNew extends PureComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    className: PropTypes.string,
    postCount: PropTypes.number,
  };

  static defaultProps = {
    className: '',
    postCount: 0,
  };

  state = {
    active: this.props.active,
    count: this.props.postCount,
  };

  UNSAFE_componentWillMount() {
    this._generateHeartParticles();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.active !== nextProps.active) {
      // Override state after optimistic update
      this.setState({ active: nextProps.active });
    }

    if (this.props.postCount !== nextProps.postCount) {
      // Override state after optimistic update
      this.setState({ count: nextProps.postCount });
    }
  }

  _generateHeartParticles = () => {
    this._particles = Array.from(Array(8)).map(
      (_particle, index) => <RecommendHeart className={CSS.particle} key={`particle-${index}`} />, // eslint-disable-line react/no-array-index-key, max-len
    );
  };

  _applyParticleStyles = isActive => {
    this._particles = this._particles.map((particle, index) => {
      const style = getParticleStyle(isActive, index);
      const className = classNames(CSS.particle, {
        [CSS.particleFade]: !isActive,
      });

      return cloneElement(particle, { style, className });
    });
  };

  onToggle = e => {
    e.preventDefault();

    const { active, count } = this.state;

    // Optimistic update to instantly trigger animation and counter
    this._applyParticleStyles(active);
    this.setState({
      active: !active,
      count: active ? count - 1 : count + 1,
    });

    setTimeout(this.props.onToggle, 1000);
  };

  render() {
    const { className } = this.props;
    const { active, count: postCount } = this.state;
    const buttonClasses = classNames(CSS.button, {
      [CSS.buttonActive]: active,
    });

    return (
      <div className={classNames(CSS.section, className)}>
        <button
          className={buttonClasses}
          onClick={this.onToggle}
          data-testid="recommend-button"
          type="button"
        >
          <RecommendHeart className={CSS.heart} {...generateSVGAttrs(active)} />
          {this._particles}
        </button>
        <span className={CSS.likesCountText}>{postCount}</span>
      </div>
    );
  }
}
