// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".backdrop-2VlsB{background-image:linear-gradient(44deg, #fca400 0%, #fecd43 100%) !important;height:100px}.backdropInner-3LNvE{transform:rotate(45deg);background-image:linear-gradient(-206deg, #1c0bb9 0%, #4f69e8 95%) !important;width:290px;height:250px;left:23px;top:-158px}\n", ""]);
// Exports
exports.locals = {
	"backdrop": "backdrop-2VlsB",
	"backdropInner": "backdropInner-3LNvE"
};
module.exports = exports;
