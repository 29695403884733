// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes fadeIn-3z8Do{from{opacity:0}to{opacity:1}}.container-1-dBp{background:linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 50%);z-index:1025;position:fixed;bottom:50px;left:0;right:0;height:284px;padding-left:16px;padding-right:16px;text-align:center;display:flex;flex-direction:column;justify-content:flex-end;opacity:0;animation:fadeIn-3z8Do 0.3s 0s ease-out forwards}@media (min-width: 600px){.container-1-dBp{height:80%;justify-content:center}}@media (min-width: 600px){.content-1-n1K{margin-top:100px}}.buyArticleCta-3gPWC{font-weight:500;font-size:16px;padding:11px 16px;width:100%;letter-spacing:-0.02em}@media (min-width: 600px){.buyArticleCta-3gPWC{max-width:214px}}.paragraph-2BOHK{font-family:\"GT-Walsheim\",sans-serif;font-size:18px;line-height:25px;text-align:center;letter-spacing:-0.02em;color:#000;margin-top:10px;margin-bottom:18px}.paragraph-2BOHK button{font:inherit;padding:0;color:#ff1060;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"container": "container-1-dBp",
	"fadeIn": "fadeIn-3z8Do",
	"content": "content-1-n1K",
	"buyArticleCta": "buyArticleCta-3gPWC",
	"paragraph": "paragraph-2BOHK"
};
module.exports = exports;
