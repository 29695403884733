// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".item-2H9uf{min-height:100vh;display:flex}@media print{.item-2H9uf{display:block}}.a-item{position:absolute !important;background-color:#fff;max-width:100%;top:0}.scrollable-16wlJ{background-color:#fff;width:100%;box-sizing:border-box;overflow-x:hidden}.loadMore-3wEUd{position:relative;height:20px;margin-bottom:50px;width:100%}.loadMore-3wEUd:after{background:#eaeaea}\n", ""]);
// Exports
exports.locals = {
	"item": "item-2H9uf",
	"scrollable": "scrollable-16wlJ",
	"loadMore": "loadMore-3wEUd"
};
module.exports = exports;
