import React from 'react';
import { Button } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import { number, func } from 'prop-types';
import formatCurrency from 'helpers/formatCurrency';
import { translate } from 'instances/i18n';
import useDisableScroll from 'hooks/useDisableScroll';
import CSS from '../CreditsBuyItemWarning/styles.scss';

const Container = styledModule.div(CSS.container);
const Content = styledModule.div(CSS.content);
const BuyArticleCta = styledModule(Button)(CSS.buyArticleCta);
const Paragraph = styledModule.p(CSS.paragraph);

const BuyItemWarningDialog = ({ balance, itemPrice, onAcquire }) => {
  useDisableScroll();

  return (
    <Container>
      <Content>
        <BuyArticleCta color="razzmatazz" onClick={onAcquire} data-testid="acquire-item-button">
          {translate('item.micropayments.buy_item_warning.buy_article', [
            formatCurrency(itemPrice),
          ])}
        </BuyArticleCta>
        <Paragraph data-testid="enough-balance">
          {translate('item.micropayments.buy_item_warning.balance', [formatCurrency(balance)])}
        </Paragraph>
      </Content>
    </Container>
  );
};

BuyItemWarningDialog.propTypes = {
  balance: number.isRequired,
  itemPrice: number.isRequired,
  onAcquire: func.isRequired,
};

export default BuyItemWarningDialog;
