import BrowserEnv from 'instances/browser_environment';

export const disabledScrolling = () => {
  document.body.style.overflow = 'hidden';

  if (BrowserEnv.isIOS()) {
    document.body.style.position = 'fixed';
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    document.body.style.maxWidth = '100%';
  }
};

export const enableScrolling = () => {
  document.body.style.overflow = '';

  if (BrowserEnv.isIOS()) {
    document.body.style.position = '';
    document.body.style.height = '';
    document.body.style.width = '';
    document.body.style.maxWidth = '';
  }
};
