import React from 'react';
import { string, bool, func, object, arrayOf } from 'prop-types';
import { compose, pure, withHandlers } from '@blendle/recompose';
import classNames from 'classnames';
import BrowserEnv from 'instances/browser_environment';
import Heart from 'components/icons/Heart';
import ArrowTopRight from 'components/icons/ArrowTopRight';
import FacebookIcon from 'components/icons/Facebook';
import TwitterIcon from 'components/icons/Twitter';
import LinkedInIcon from 'components/icons/LinkedIn';
import WhatsAppIcon from 'components/icons/WhatsApp';
import EmailIcon from 'components/icons/Envelope';
import styledModule from 'helpers/styledModule';
import { SHARING_PLATFORM } from 'app-constants';
import withHideOnScroll from '../../higher-order-components/withHideOnScroll';
import withShareToEmail from '../../higher-order-components/withShareToEmail';
import OpenInAppButton from './OpenInAppButton';
import CSS from './styles.scss';

const icons = new Map([
  [SHARING_PLATFORM.FACEBOOK, FacebookIcon],
  [SHARING_PLATFORM.TWITTER, TwitterIcon],
  [SHARING_PLATFORM.WHATSAPP, WhatsAppIcon],
  [SHARING_PLATFORM.EMAIL, EmailIcon],
  [SHARING_PLATFORM.LINKEDIN, LinkedInIcon],
]);

const BaseBar = styledModule.div(CSS.baseBar);
const Button = styledModule.button(CSS.button);
const MobileStickySharingWrapper = styledModule.div(CSS.mobileStickySharing);
const ShareArrow = styledModule.div(CSS.sharerArrow);

const MobileStickySharing = ({
  itemId,
  isHiddenByScroll,
  blendleButtonActive,
  blendleButtonLoading,
  onToggleBlendleShare,
  showSharer,
  isAcquired,
  onToggleSharer,
  onShare,
  hideOpenInApp,
  openItemInAndroid,
  analytics,
  socialPlatforms,
}) => (
  <MobileStickySharingWrapper className={isHiddenByScroll ? CSS.isHidden : ''}>
    <BaseBar>
      <div>
        <Button type="button" onClick={onToggleBlendleShare}>
          <Heart
            className={classNames(
              CSS.icon,
              CSS.heart,
              blendleButtonActive && CSS.heartActive,
              blendleButtonLoading && CSS.heartActiveLoading,
            )}
          />
        </Button>
        {isAcquired && (
          <Button type="button" onClick={onToggleSharer}>
            <ArrowTopRight className={classNames(CSS.icon, CSS.arrowTopRight)} />
          </Button>
        )}
      </div>
      {BrowserEnv.platformHasApp() && !hideOpenInApp && (
        <OpenInAppButton
          itemId={itemId}
          openItemInAndroid={openItemInAndroid}
          analytics={analytics}
        />
      )}
      <ShareArrow className={showSharer ? CSS.sharerArrowOpen : ''} />
    </BaseBar>

    <div className={classNames(CSS.sharer, showSharer && CSS.sharerOpen)}>
      {socialPlatforms.map((platform) => {
        const Icon = icons.get(platform);
        return (
          <button type="button" onClick={onShare(platform)} key={platform}>
            <Icon className={classNames(CSS.icon, CSS[platform])} />
          </button>
        );
      })}
    </div>
  </MobileStickySharingWrapper>
);

MobileStickySharing.propTypes = {
  itemId: string.isRequired,
  isHiddenByScroll: bool.isRequired,
  blendleButtonActive: bool.isRequired,
  blendleButtonLoading: bool.isRequired,
  onToggleBlendleShare: func.isRequired,
  hideOpenInApp: bool.isRequired,
  showSharer: bool.isRequired,
  isAcquired: bool.isRequired,
  onToggleSharer: func.isRequired,
  onShare: func.isRequired,
  openItemInAndroid: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  analytics: object.isRequired,
  socialPlatforms: arrayOf(string).isRequired,
};

export default compose(
  withHideOnScroll,
  withShareToEmail,
  withHandlers({
    onShare: (props) => (platform) => (event) => {
      event.preventDefault();
      if (platform === 'email') {
        props.setEmailDialogOpen(true);
        props.openEmailShareDialog(true);
      } else {
        props.onSocialShare(platform);
      }
      props.setSharerVisibility(false);
    },
  }),
  pure,
)(MobileStickySharing);
