import { STATUS_INITIAL, STATUS_PENDING, STATUS_OK, STATUS_ERROR } from 'app-constants';
import alt from 'instances/altInstance';
import { getItemId } from 'selectors/item';
import ReadMoreActions from '../actions/ReadMoreActions';

class ReadMoreStore {
  constructor() {
    this.bindActions(ReadMoreActions);

    this.state = {
      status: STATUS_INITIAL,
      sections: new Map(),
      sectionIds: [],
      error: null,
    };
  }

  onFetchReadMore() {
    this.setState({
      status: STATUS_PENDING,
      sectionIds: [],
      error: null,
    });
  }

  onFetchReadMoreSuccess(fetchedSections) {
    const sections = new Map(this.state.sections);
    const sectionIds = [];

    fetchedSections.forEach(section => {
      sectionIds.push(section.id);
      const currentSection = sections.get(section.id);

      sections.set(section.id, {
        ...currentSection,
        ...section,
      });
    });

    this.setState({
      status: STATUS_OK,
      sections,
      sectionIds,
      error: null,
    });
  }

  onFetchReadMoreError(error) {
    this.setState({
      status: STATUS_ERROR,
      sectionIds: [],
      error,
    });
  }

  onFetchSectionFeedSuccess({ sectionId, tiles }) {
    const sections = new Map(this.state.sections);

    sections.set(sectionId, {
      ...sections.get(sectionId),
      item_uids: tiles.map(getItemId),
    });

    this.setState({
      sections,
    });
  }
}

export default alt.createStore(ReadMoreStore, 'ReadMoreStore');
