// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".illustration-1PQ4c{position:absolute;z-index:1;left:0;top:30px;display:flex;justify-content:center;width:100%}\n", ""]);
// Exports
exports.locals = {
	"illustration": "illustration-1PQ4c"
};
module.exports = exports;
