// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".audioBanner-1zAil{background:rgba(0,0,0,0.03);max-width:640px;margin:0 auto;border-radius:4px;padding:15px 20px;display:flex;justify-content:center;align-items:center}@media print{.audioBanner-1zAil{display:none}}.content-1ehvC{align-items:center;color:#474747}.iconWrapper-1_KHu{margin-right:15px;width:22px;height:18px}.text-1VRVj{font-family:\"GT-Walsheim\",sans-serif;font-size:14px;flex:1 0}.text-1VRVj a{color:inherit}\n", ""]);
// Exports
exports.locals = {
	"audioBanner": "audioBanner-1zAil",
	"content": "content-1ehvC",
	"iconWrapper": "iconWrapper-1_KHu",
	"text": "text-1VRVj"
};
module.exports = exports;
