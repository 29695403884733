// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".streamer-155oA{opacity:0;transform:translateY(20px);transition:opacity 0.4s ease-out, transform 0.4s ease-out}.visible-vbV4v{transform:translateY(0);opacity:1}\n", ""]);
// Exports
exports.locals = {
	"streamer": "streamer-155oA",
	"visible": "visible-vbV4v"
};
module.exports = exports;
