// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper-3k4y9{position:fixed;top:0;right:0;bottom:0;left:0;z-index:9999}.overlay-iNaoE{position:absolute;width:100%;height:100%;top:0;left:0;background-color:#fff;transition:opacity 200ms 100ms ease-out;opacity:0;pointer-events:none}.figure-qm6fo{display:flex;flex-direction:column;justify-content:center;align-items:center}.figCaption-1mG3q{bottom:0;width:100%;text-align:right;padding-right:10px;background:linear-gradient(to bottom, transparent, rgba(0,0,0,0.3));position:absolute;color:#fff}.img-3ijgS{width:100%;height:100%}.caption-2JfOG{font-weight:600}.credit-ueJd4{margin-left:5px}.visible-3fbbu{transition:opacity 100ms ease-out;opacity:1;pointer-events:auto}.closeButton-1cvNV{position:absolute;top:5px;left:5px;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper-3k4y9",
	"overlay": "overlay-iNaoE",
	"figure": "figure-qm6fo",
	"figCaption": "figCaption-1mG3q",
	"img": "img-3ijgS",
	"caption": "caption-2JfOG",
	"credit": "credit-ueJd4",
	"visible": "visible-3fbbu",
	"closeButton": "closeButton-1cvNV"
};
module.exports = exports;
