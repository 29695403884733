// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header-3OlWX{width:100%}.suggestionSectionHeader-ZfkI3{font-family:\"GT-Walsheim\",sans-serif;display:block;text-align:center;margin:0 auto;font-weight:600;margin-bottom:20px;font-size:40px}\n", ""]);
// Exports
exports.locals = {
	"header": "header-3OlWX",
	"suggestionSectionHeader": "suggestionSectionHeader-ZfkI3"
};
module.exports = exports;
