import { useEffect } from 'react';
import { disabledScrolling, enableScrolling } from 'helpers/disableScroll';

const useDisableScroll = () => {
  useEffect(() => {
    disabledScrolling();

    return () => {
      enableScrolling();
    };
  }, []);
};

export default useDisableScroll;
