import altConnect from 'higher-order-components/altConnect';
import ItemStore from 'stores/ItemStore';
import Entities from '../components/Entities';

function mapStateToProps({ itemState }) {
  const { entities } = itemState;

  return { entities };
}

mapStateToProps.stores = {
  ItemStore,
};

export default altConnect(mapStateToProps)(Entities);
