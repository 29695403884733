import React from 'react';
import { wrapDisplayName } from '@blendle/recompose';

const tap = (tapFunction) => (ComposedComponent) => {
  function Tap(props) {
    tapFunction(props);

    return <ComposedComponent {...props} />;
  }

  Tap.displayName = wrapDisplayName(ComposedComponent, 'tap');

  return Tap;
};

export default tap;
