import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { pure } from '@blendle/recompose';
import { ChipRow } from '@blendle/lego';
import EntityContainer from '../../containers/EntityContainer';
import CSS from './style.scss';

function Entities({ entities }) {
  return (
    <div className={CSS.entities}>
      <ChipRow>
        {entities.map(({ name }) => (
          <EntityContainer key={name} entityId={name} />
        ))}
      </ChipRow>
    </div>
  );
}

Entities.propTypes = {
  entities: arrayOf(
    shape({
      name: string.isRequired,
    }),
  ).isRequired,
};

export default pure(Entities);
