import React from 'react';
import AuthStore from 'stores/AuthStore';
import TilesStore from 'stores/TilesStore';
import ItemStore from 'stores/ItemStore';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import useAlt from 'hooks/useAlt';
import { FEATURE_CAPABILITY_BLENDLE_PREMIUM } from 'app-constants';
import { hasCapability } from 'selectors/capabilities';
import { convertFromCents } from 'helpers/formatCurrency';
import SignUpRewardDialog from '../components/SignUpRewardDialog';

const SignUpRewardDialogContainer = () => {
  const [itemState, tilesState, authState, capabilitiesState] = useAlt([
    ItemStore,
    TilesStore,
    AuthStore,
    FeatureCapabilitiesStore,
  ]);
  const hasPremium = hasCapability(capabilitiesState, FEATURE_CAPABILITY_BLENDLE_PREMIUM);

  if (hasPremium) {
    return null;
  }

  const { justAcquired, selectedItemId } = itemState;
  const { tiles } = tilesState;
  const tile = tiles.get(selectedItemId);

  if (!tile) {
    return null;
  }

  const { price } = tile;
  const { user } = authState;

  const balance = parseFloat(user.get('balance'), 10);

  const gift = 2.5;
  const realArticlePrice = convertFromCents(price);
  const userHasntBoughtAnythingSoFar = balance === gift - realArticlePrice;

  if (justAcquired && realArticlePrice > 0 && userHasntBoughtAnythingSoFar) {
    return <SignUpRewardDialog gift={gift} price={realArticlePrice} />;
  }

  return null;
};

export default SignUpRewardDialogContainer;
