import AuthStore from 'stores/AuthStore';
import ItemActions from 'actions/ItemActions';
import { getSocialOrigin } from './socialOrigin';

export default function loadItem(itemId, sharerQueryParam) {
  const { user } = AuthStore.getState();
  const socialOrigin = getSocialOrigin(itemId, sharerQueryParam);

  ItemActions.fetchContent.defer(itemId, user.id, socialOrigin);
}
