import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  ArrowDownIcon,
  RefundIcon,
  PenIcon,
  PocketIcon,
  PrintIcon,
  BrowseIcon,
} from '@blendle/lego';
import { removeTrailingSlash } from 'helpers/url';
import Settings from 'controllers/settings';
import classNames from 'classnames';
import formatCurrency from 'helpers/formatCurrency';
import getEditorUrl from 'helpers/getEditorUrl';
import { translate } from 'instances/i18n';
import Link from 'components/Link';
import styledModule from 'helpers/styledModule';
import ItemStats from './ItemStats';
import CSS from './style.scss';

const IconWrapper = styledModule.div(CSS.iconWrapper);

const DropdownLink = ({ children, className, ...props }) => (
  <Link className={classNames(CSS.dropdownItem, className)} {...props}>
    {children}
  </Link>
);

DropdownLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

DropdownLink.defaultProps = {
  className: '',
};

const DropdownButton = ({ children, className, ...props }) => (
  <button type="button" className={classNames(CSS.dropdownItem, className)} {...props}>
    {children}
  </button>
);

DropdownButton.propTypes = DropdownLink.propTypes;

DropdownButton.defaultProps = DropdownLink.defaultProps;

const OptionsDropdown = memo(
  ({
    refundable,
    itemPrice,
    onClickPocket,
    providerId,
    issueId,
    itemId,
    date,
    itemLength,
    onClickIssue,
    onPrint,
    hasNewsstand,
    editorButtonEnabled,
  }) => {
    const renderTriggerButton = isOpen => (
      <button type="button" className={classNames(CSS.toggleButton, { [CSS.open]: isOpen })}>
        <ArrowDownIcon className={CSS.arrow} />
      </button>
    );

    return (
      <Dropdown
        triggerButton={renderTriggerButton}
        className={CSS.dropdownOptions}
        align="right"
        closeOnItemClicked
      >
        <DropdownButton onClick={onClickPocket}>
          <IconWrapper>
            <PocketIcon />
          </IconWrapper>
          {translate('item.navigation.pocket')}
        </DropdownButton>
        {refundable && (
          <DropdownLink
            href={`${removeTrailingSlash(window.location.pathname)}/refund`}
            onClick={onClickIssue}
            data-testid="refund-link"
          >
            <IconWrapper>
              <RefundIcon />
            </IconWrapper>
            {translate('item.navigation.refund', formatCurrency(itemPrice))}
          </DropdownLink>
        )}
        {editorButtonEnabled && (
          <DropdownLink
            className={CSS.lnkEditor}
            href={getEditorUrl(itemId)}
            target="_blank"
            data-testid="editor-link"
          >
            <IconWrapper>
              <PenIcon width={18} height={18} />
            </IconWrapper>
            {translate('item.navigation.editor')}
          </DropdownLink>
        )}
        <DropdownButton onClick={onPrint}>
          <IconWrapper>
            <PrintIcon />
          </IconWrapper>
          {translate('item.navigation.print')}
        </DropdownButton>
        {!Settings.embedded && hasNewsstand && issueId ? (
          <DropdownLink
            href={`/issue/${providerId}/${issueId}`}
            onClick={onClickIssue}
            data-testid="issue-link"
          >
            <IconWrapper>
              <BrowseIcon width={18} />
            </IconWrapper>
            {translate('item.navigation.issue')}
          </DropdownLink>
        ) : null}
        <ItemStats itemLength={itemLength} date={date} />
      </Dropdown>
    );
  },
);

OptionsDropdown.propTypes = {
  onClickPocket: PropTypes.func.isRequired,
  onClickIssue: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  refundable: PropTypes.bool,
  itemPrice: PropTypes.number.isRequired,
  providerId: PropTypes.string.isRequired,
  issueId: PropTypes.string,
  itemId: PropTypes.string.isRequired,
  itemLength: PropTypes.objectOf(PropTypes.number).isRequired,
  date: PropTypes.string.isRequired,
  editorButtonEnabled: PropTypes.bool.isRequired,
  hasNewsstand: PropTypes.bool.isRequired,
};

OptionsDropdown.defaultProps = {
  refundable: false,
  issueId: undefined,
};

export default OptionsDropdown;
