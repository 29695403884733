import React, { Component } from 'react';
import AltContainer from 'alt-container';
import ItemStore from 'stores/ItemStore';

const getCollapseTarget = (currentTarget, currentY, threshold) =>
  Math.max(currentTarget || currentY, threshold);

const getShowTarget = (currentTarget, currentY, threshold) =>
  currentTarget || Math.abs(currentY - threshold);

export default (ComposedComponent, { scrollDownThreshold = 50, scrollUpThreshold = 40 } = {}) =>
  class WithHideOnScroll extends Component {
    lastWindowInnerHeight = window.innerHeight;

    isHidden = false;

    determineHide = (currentY, viewportHeight) => {
      const previousY = this.scrollY || 0;

      if (viewportHeight && this.lastWindowInnerHeight > viewportHeight) {
        // The window got bigger, browser chrome become visible
        this.isHidden = false;
      } else if (viewportHeight && this.lastWindowInnerHeight < viewportHeight && currentY > 100) {
        // The window got smaler, browser chrome hidden
        this.isHidden = true;
      } else if (currentY < previousY) {
        // Scroll Up
        this.collapseTarget = null;
        this.showTarget = getShowTarget(this.showTarget, currentY, scrollUpThreshold);

        if (currentY <= this.showTarget) {
          this.isHidden = false;
        }
      } else if (currentY > previousY) {
        // Scroll Down
        this.showTarget = null;
        this.collapseTarget = getCollapseTarget(this.collapseTarget, currentY, scrollDownThreshold);

        if (currentY > this.collapseTarget) {
          this.isHidden = true;
        }
      }

      this.lastWindowInnerHeight = viewportHeight;

      this.scrollY = currentY;
      return this.isHidden;
    };

    mapStateToProps = ({ itemState }) => {
      const { scrollPixelsFromTop, maxReadingPercentage, viewportHeight } = itemState;

      return {
        isHiddenByScroll: this.determineHide(scrollPixelsFromTop, viewportHeight),
        contentWasFullyVisible: maxReadingPercentage >= 100,
      };
    };

    render() {
      return (
        <AltContainer
          stores={{ itemState: ItemStore }}
          transform={this.mapStateToProps}
          render={props => <ComposedComponent {...this.props} {...props} />}
        />
      );
    }
  };
