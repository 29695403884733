import React, { memo } from 'react';
import TilesStore from 'stores/TilesStore';
import ItemStore from 'stores/ItemStore';
import useAlt from 'hooks/useAlt';
import ArticleFooter from '../components/ArticleFooter';
import { isContentReady } from '../helpers/readerStatus';

const ArticleFooterContainer = memo(() => {
  const [tilesState, itemState] = useAlt([TilesStore, ItemStore]);
  const { tiles } = tilesState;
  const { selectedItemId, content } = itemState;
  const tile = tiles.get(selectedItemId);

  if (!tile && !isContentReady(content)) {
    return null;
  }

  return <ArticleFooter itemId={selectedItemId} />;
});

export default ArticleFooterContainer;
