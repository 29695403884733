import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogBody } from '@blendle/lego';
import { translate } from 'instances/i18n';

function LoadFailedRetry({ onDismiss, onRetry }) {
  return (
    <Dialog onClose={onDismiss}>
      <DialogBody>
        <p>{translate('item.errors.load_failed')}</p>
        <Button onClick={onRetry}>{translate('item.buttons.retry')}</Button>
      </DialogBody>
    </Dialog>
  );
}

LoadFailedRetry.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default LoadFailedRetry;
