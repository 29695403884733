// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".mobileStickySharing-1uoJn{position:fixed;bottom:0;left:0;right:0;transition:transform 0.2s ease-out;display:flex;flex-direction:column-reverse;z-index:1025}.mobileStickySharing-1uoJn.isHidden-2om3c{transform:translateY(100%);transform:translate3d(0, 100%, 0)}@media print{.mobileStickySharing-1uoJn{display:none}}.baseBar-14yQ6{display:flex;position:relative;padding:0 10px;justify-content:space-between;align-items:center;height:50px;background-color:#fff;border-top:1px solid #f2f2f2;color:#ff6255;z-index:1026}.sharerArrow-1G5R8{position:absolute;top:-8px;left:78px;width:16px;height:16px;z-index:1050;transform:rotate(45deg) scale(0);transition:transform 200ms ease-in-out;border-right:1px solid #f2f2f2;border-bottom:1px solid #f2f2f2;background-color:#fff}.sharerArrowOpen-s1kfa{transform:rotate(45deg) scale(1)}.heart-1uKtN path{fill:none;stroke:#ff6255;stroke-width:1px;stroke-linecap:round}.heartActive-1YtW4 path{fill:#ff6255;stroke-width:0}.heartLoading-U2O4i{opacity:0.3;pointer-events:none}.arrowTopRight-1Vqfx{color:#948784}.icon-l_v9v{height:18px;padding:0 10px;overflow:visible;cursor:pointer}.button-1FIHv{padding:5px}.appStoreLink-1rqHR{font-family:\"GT-Walsheim\",sans-serif;text-decoration:underline;font-size:14px;color:#ff1060;padding:0 10px;cursor:pointer}.sharer-2bec3{height:50px;position:relative;transform:translateY(100%);background-color:#fff;border-top:1px solid #f2f2f2;transform-origin:center bottom;transition:transform 200ms ease-in-out;padding:0 10px;display:flex;justify-content:space-between;align-items:center}.sharerOpen-HGgYs{transform:translateY(0)}.facebook-1axTr{color:#3a559b}.twitter-1R5Wg{color:#55acee}.whatsapp-ZSFRY{color:#4dc247}.email-Mpm1x{color:#948784}.linkedin-as8yS{color:#0077b5}\n", ""]);
// Exports
exports.locals = {
	"mobileStickySharing": "mobileStickySharing-1uoJn",
	"isHidden": "isHidden-2om3c",
	"baseBar": "baseBar-14yQ6",
	"sharerArrow": "sharerArrow-1G5R8",
	"sharerArrowOpen": "sharerArrowOpen-s1kfa",
	"heart": "heart-1uKtN",
	"heartActive": "heartActive-1YtW4",
	"heartLoading": "heartLoading-U2O4i",
	"arrowTopRight": "arrowTopRight-1Vqfx",
	"icon": "icon-l_v9v",
	"button": "button-1FIHv",
	"appStoreLink": "appStoreLink-1rqHR",
	"sharer": "sharer-2bec3",
	"sharerOpen": "sharerOpen-HGgYs",
	"facebook": "facebook-1axTr",
	"twitter": "twitter-1R5Wg",
	"whatsapp": "whatsapp-ZSFRY",
	"email": "email-Mpm1x",
	"linkedin": "linkedin-as8yS"
};
module.exports = exports;
