// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loading-1Utsi{position:absolute;height:100vh;top:0;left:0;background:#fff;width:100vw;display:flex;z-index:9999;justify-content:center;align-items:center}@media screen and (min-width: 600px){.loading-1Utsi{height:100vh}}.loadingIcon-z77yv:after{background:#eaeaea}\n", ""]);
// Exports
exports.locals = {
	"loading": "loading-1Utsi",
	"loadingIcon": "loadingIcon-z77yv"
};
module.exports = exports;
