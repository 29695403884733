// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemStats-2Cx55{display:flex;padding:10px;background:#f4f4f4;font-size:.85714em;border-bottom-left-radius:7px;border-bottom-right-radius:7px}.label-1r7YZ{color:#948784}.value-arNtl{padding-left:10px}\n", ""]);
// Exports
exports.locals = {
	"itemStats": "itemStats-2Cx55",
	"label": "label-1r7YZ",
	"value": "value-arNtl"
};
module.exports = exports;
