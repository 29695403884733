// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemFeedback-2pAeL{padding:0 15px;font-size:11px;font-family:\"GT-Walsheim\",sans-serif;transition:opacity 0.3s ease-out;color:#948784;will-change:opacity}.itemFeedback-2pAeL:only-child{margin:0 auto}.itemFeedback-2pAeL a{color:#333}.l-mobile .itemFeedback-2pAeL{font-size:12px;padding:0;text-align:center;line-height:16px}.l-mobile .itemFeedback-2pAeL a:before{content:'';display:block}.l-desktop .itemFeedback-2pAeL{padding-left:0;padding-right:0}.isLoading-2gsdD{opacity:0.6;pointer-events:none}.isLoading-2gsdD a{cursor:default}\n", ""]);
// Exports
exports.locals = {
	"itemFeedback": "itemFeedback-2pAeL",
	"isLoading": "isLoading-2gsdD"
};
module.exports = exports;
