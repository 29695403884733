// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".itemMeta-CNDPx{width:100%;position:absolute;z-index:1;font-size:12px;padding-top:55px;font-weight:normal;line-height:inherit;text-align:center}.date-3SKW6{display:block;text-align:center;font-size:11px;line-height:1em;position:relative;top:-6px}.time-2SRdE{padding:0 8px 3px;border-radius:2px;color:#948784}.printDate-B8Iok{text-align:center;font-size:11px;line-height:1em;position:relative;top:-6px}.printDate-B8Iok,.printNotice-3mrdO{display:none}@media print{.container-1fNDo{position:relative;display:block;text-align:center}.printDate-B8Iok{display:block !important}.date-3SKW6{display:none !important}.printNotice-3mrdO{display:block;text-align:left;font-size:12px;margin-bottom:20px;color:#948784 !important}}\n", ""]);
// Exports
exports.locals = {
	"itemMeta": "itemMeta-CNDPx",
	"date": "date-3SKW6",
	"time": "time-2SRdE",
	"printDate": "printDate-B8Iok",
	"printNotice": "printNotice-3mrdO",
	"container": "container-1fNDo"
};
module.exports = exports;
