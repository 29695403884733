import React, { useState } from 'react';
import { Button } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import { number, string, func, bool } from 'prop-types';
import formatCurrency from 'helpers/formatCurrency';
import { translate } from 'instances/i18n';
import useDisableScroll from 'hooks/useDisableScroll';
import { disabledScrolling } from 'helpers/disableScroll';
import CreditsPaymentSuccessDialog from 'components/dialogues/CreditsPaymentSuccessDialog';
import CSS from './styles.scss';
import BuyCreditsExplanationDialog from './BuyCreditsExplanationDialog';
import CreditsAnnouncementDialog from './CreditsAnnouncementDialog';

const Container = styledModule.div(CSS.container);
const Content = styledModule.div(CSS.content);
const BuyArticleCta = styledModule(Button)(CSS.buyArticleCta);
const Paragraph = styledModule.p(CSS.paragraph);

const CreditsBuyItemWarning = ({
  balance,
  itemPrice,
  buyCreditsPath,
  onAcquire,
  onShowOutOfBalanceDialog,
  onDismissOutOfBalanceDialog,
  didCreditsTopup,
  numberOfCredits,
  itemId,
  backgroundColor,
  fontColor,
}) => {
  const [isTopupExplanationDialogOpen, setIsTopupExplanationDialogOpen] = useState(false);
  const [isCreditsPaymentSuccessDialog, setIsCreditsPaymentSuccessDialog] = useState(
    didCreditsTopup,
  );

  useDisableScroll();

  const hasEnoughBalance = balance >= itemPrice;

  const openBuyCreditsExplanationDialog = () => {
    setIsTopupExplanationDialogOpen(true);
  };

  const disableScrollAfterClosingDialog = () => {
    // This message uses the same technique as the dialog to disable the scrolling.
    // When the dialog closes the scrolling is enabled again, so we need to make sure we enable it again
    // Doing it in the next tick to make sure it happens after the dialog is unmounted
    setTimeout(disabledScrolling);
  };

  const closeBuyCreditsExplanationDialog = () => {
    onDismissOutOfBalanceDialog();
    setIsTopupExplanationDialogOpen(false);
    disableScrollAfterClosingDialog();
  };

  const closeCreditsPaymentSuccessDialog = () => {
    setIsCreditsPaymentSuccessDialog(false);
    disableScrollAfterClosingDialog();
  };

  const ctaCopy = translate('item.credits.buy_item_warning.buy_article', [
    formatCurrency(itemPrice),
  ]);

  return (
    <>
      <Container
        data-test-id="credits-buy-item-warning"
        style={{
          background: `linear-gradient(180deg, rgba(255,255,255,0) 0%, ${backgroundColor} 50%)`,
        }}
      >
        <Content>
          {hasEnoughBalance ? (
            <>
              <BuyArticleCta data-testid="acquire-cta" color="razzmatazz" onClick={onAcquire}>
                {ctaCopy}
              </BuyArticleCta>
              <Paragraph data-testid="enough-balance" style={{ color: fontColor }}>
                {translate('item.credits.buy_item_warning.balance', [formatCurrency(balance)])}
              </Paragraph>
            </>
          ) : (
            <>
              <BuyArticleCta
                data-testid="acquire-cta"
                color="razzmatazz"
                onClick={openBuyCreditsExplanationDialog}
              >
                {ctaCopy}
              </BuyArticleCta>
              <Paragraph data-testid="not-enough-balance" style={{ color: fontColor }}>
                {translate('item.credits.buy_item_warning.not_enough_balance.pre_cta', [
                  formatCurrency(balance),
                ])}{' '}
                <button type="button" onClick={openBuyCreditsExplanationDialog}>
                  {translate('item.credits.buy_item_warning.not_enough_balance.cta')}
                </button>{' '}
                {translate('item.credits.buy_item_warning.not_enough_balance.post_cta')}
              </Paragraph>
            </>
          )}
        </Content>
        <BuyCreditsExplanationDialog
          data-testid="buy-credits-explanation-dialog"
          isOpen={isTopupExplanationDialogOpen}
          onClose={closeBuyCreditsExplanationDialog}
          itemPrice={itemPrice}
          buyCreditsPath={buyCreditsPath}
          onShowOutOfBalanceDialog={onShowOutOfBalanceDialog}
        />
        {didCreditsTopup && (
          <CreditsPaymentSuccessDialog
            data-testid="credits-payment-success-dialog"
            onClose={closeCreditsPaymentSuccessDialog}
            numberOfCredits={numberOfCredits}
            isOpen={isCreditsPaymentSuccessDialog}
          />
        )}
      </Container>
      <CreditsAnnouncementDialog itemId={itemId} />
    </>
  );
};

CreditsBuyItemWarning.propTypes = {
  itemId: string.isRequired,
  balance: number.isRequired,
  itemPrice: number.isRequired,
  buyCreditsPath: string.isRequired,
  onAcquire: func.isRequired,
  onShowOutOfBalanceDialog: func.isRequired,
  onDismissOutOfBalanceDialog: func.isRequired,
  didCreditsTopup: bool.isRequired,
  numberOfCredits: string,
  backgroundColor: string,
  fontColor: string,
};

CreditsBuyItemWarning.defaultProps = {
  numberOfCredits: undefined,
  backgroundColor: '#FFFFFF',
  fontColor: undefined,
};

export default CreditsBuyItemWarning;
