import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isTabletBreakpoint } from 'helpers/viewport';
import FullWidthImage from './FullWidthImage';
import InlineImage from './InlineImage';

function isFullWidthImage(imageWidth, imageHeight) {
  const isLandscape = imageWidth >= imageHeight;

  return isTabletBreakpoint() || isLandscape;
}

function ItemImage(props) {
  const { fragment } = props;
  const originalImage = fragment.sizes.original;
  const isFullWidth = isFullWidthImage(originalImage.width, originalImage.height);

  if (isFullWidth) {
    return <FullWidthImage fragment={fragment} />;
  }

  return <InlineImage fragment={fragment} />;
}

ItemImage.propTypes = {
  fragment: PropTypes.object.isRequired,
};

export default ItemImage;
