import React, { useEffect } from 'react';
import { func, number, string, shape, bool } from 'prop-types';
import { DEFAULT_CREDITS_BUNDLE } from 'app-constants';
import Link from 'components/Link';
import formatCurrency from 'helpers/formatCurrency';
import { Dialog, DialogBody, Button } from '@blendle/lego';
import styledModule from 'helpers/styledModule';
import { translate } from 'instances/i18n';
import CSS from './styles.scss';

const StyledDialogBody = styledModule(DialogBody)(CSS.dialogBody);
const Title = styledModule.h1(CSS.title);
const Paragraph = styledModule.p(CSS.paragraph);
const BuyCreditsCta = styledModule(Button)(CSS.buyCreditsCta);
const CloseCta = styledModule.button(CSS.closeCta);

const BuyCreditsExplanationDialog = ({
  isOpen,
  onClose,
  defaultCreditsBundle,
  itemPrice,
  buyCreditsPath,
  onShowOutOfBalanceDialog,
}) => {
  useEffect(() => {
    if (isOpen) {
      onShowOutOfBalanceDialog();
    }
  }, [isOpen]);
  const { amount, formattedPrice } = defaultCreditsBundle;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <StyledDialogBody>
        <Title>
          {translate('item.credits.buy_credits_dialog.title', [formatCurrency(itemPrice)])}
        </Title>
        <Paragraph>{translate('item.credits.buy_credits_dialog.text')}</Paragraph>
        <BuyCreditsCta color="razzmatazz" href={buyCreditsPath} component={Link}>
          {translate('item.credits.buy_credits_dialog.submit', [amount, formattedPrice])}
        </BuyCreditsCta>
        <CloseCta type="button" onClick={onClose}>
          {translate('item.credits.buy_credits_dialog.cancel')}
        </CloseCta>
      </StyledDialogBody>
    </Dialog>
  );
};

BuyCreditsExplanationDialog.propTypes = {
  isOpen: bool.isRequired,
  itemPrice: number.isRequired,
  buyCreditsPath: string.isRequired,
  onClose: func.isRequired,
  defaultCreditsBundle: shape({
    amount: number.isRequired,
    formattedPrice: string.isRequired,
  }),
  onShowOutOfBalanceDialog: func.isRequired,
};

BuyCreditsExplanationDialog.defaultProps = {
  defaultCreditsBundle: DEFAULT_CREDITS_BUNDLE,
};

export default BuyCreditsExplanationDialog;
