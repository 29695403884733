// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".inlineImageContainer-3s4q0{position:relative;height:0;width:100%}@media print{.inlineImageContainer-3s4q0{display:none}}.imageElement-30gpv{margin:10px 45px 40px 0;max-width:320px;float:left;clear:left;max-height:none;position:relative;z-index:10}@media screen and (min-width: 960px){.imageElement-30gpv{margin-left:-120px}}.align-right .imageElement-30gpv{float:right;clear:right;margin-right:-120px;margin-left:60px}.meta-2U0H1{position:relative;max-width:320px;top:-30px;font-size:.71429em;clear:both;margin-left:0;margin-right:45px;float:left;line-height:1em}@media screen and (min-width: 960px){.meta-2U0H1{margin-left:-120px}}.align-right .meta-2U0H1{text-align:right;float:right;margin-right:-120px;margin-left:0}.credit-1u_8Z{margin-left:5px}.align-right .credit-1u_8Z{margin-left:0;margin-right:5px}.caption-3FOFc{font-weight:700}.caption-3FOFc,.credit-1u_8Z{display:inline}\n", ""]);
// Exports
exports.locals = {
	"inlineImageContainer": "inlineImageContainer-3s4q0",
	"imageElement": "imageElement-30gpv",
	"meta": "meta-2U0H1",
	"credit": "credit-1u_8Z",
	"caption": "caption-3FOFc"
};
module.exports = exports;
