// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".share-3iXV3{max-width:640px;display:flex;align-items:center;margin-bottom:25px;justify-content:space-between;padding:0 15px}@media screen and (min-width: 600px){.share-3iXV3{margin-bottom:40px;padding:0}}\n", ""]);
// Exports
exports.locals = {
	"share": "share-3iXV3"
};
module.exports = exports;
