// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".progress-3E14X{z-index:1100;position:fixed;top:0;height:2px;background-image:linear-gradient(-135deg, #f63381 0%, #cd5ceb 100%);width:100%;transition:transform 0.5s ease-out}\n", ""]);
// Exports
exports.locals = {
	"progress": "progress-3E14X"
};
module.exports = exports;
