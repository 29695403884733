// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".shareButton-3A5CG{cursor:pointer;vertical-align:top;position:relative;transition:transform 0.2s ease-out;width:35px;height:35px;border-radius:100%;display:inline-flex;justify-content:center;align-items:center;border:1px solid;margin:0 12px 0 0}.shareButton-3A5CG:hover{transform:translateY(-3px)}.shareButton-3A5CG:last-of-type{margin-right:0}.icon-147Q8{vertical-align:middle;width:15px;height:15px}.facebook-2ep9G{border-color:#3a559b}.facebook-2ep9G path{fill:#3a559b}.twitter-3XgHe{border-color:#55acee}.twitter-3XgHe path{fill:#55acee}.email-cywl5{border-color:#948784}.email-cywl5 path{fill:#948784}.linkedin-V9GZ8{border-color:#0077b5}.linkedin-V9GZ8 path{fill:#0077b5}.whatsapp-GFpAT{border-color:#4dc247}.whatsapp-GFpAT path{fill:#4dc247}\n", ""]);
// Exports
exports.locals = {
	"shareButton": "shareButton-3A5CG",
	"icon": "icon-147Q8",
	"facebook": "facebook-2ep9G",
	"twitter": "twitter-3XgHe",
	"email": "email-cywl5",
	"linkedin": "linkedin-V9GZ8",
	"whatsapp": "whatsapp-GFpAT"
};
module.exports = exports;
