// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".suggestionSections-2RDvR{background:#fafafa;padding-top:40px;box-shadow:0 1px 10px rgba(0,0,0,0.05) inset;display:flex;flex-direction:column;justify-content:center}@media print{.suggestionSections-2RDvR{display:none}}\n", ""]);
// Exports
exports.locals = {
	"suggestionSections": "suggestionSections-2RDvR"
};
module.exports = exports;
