import React, { PureComponent } from 'react';
import AuthStore from 'stores/AuthStore';
import TilesStore from 'stores/TilesStore';
import { getTileManifest } from 'selectors/tiles';
import ItemStore from 'stores/ItemStore';
import AltContainer from 'alt-container';
import { createItemUriFromManifest } from 'helpers/prettyUrl';
import PurchaseNotice from '../components/PurchaseNotice';
import withItemNotifications from '../higher-order-components/withItemNotifications';

class PurchaseNoticeContainer extends PureComponent {
  // eslint-disable-next-line react/prop-types
  _renderPurchaseNotice = ({ itemState, authState, tilesState }) => {
    const { justAcquired, selectedItemId } = itemState;
    const { tiles } = tilesState;
    const tile = tiles.get(selectedItemId);

    if (!tile) {
      return null;
    }

    const manifest = getTileManifest(tile);
    const { price } = tile;
    const { user } = authState;
    const hasProviderSubscription = user.hasActiveSubscription(manifest.provider.id);

    if (user.hasActivePremiumSubscription()) {
      return null; // ItemNotFreeForPremiumUserNotification is shown in the withItemNotifications hoc
    }

    if (justAcquired && user.get('reads') < 2) {
      return null; // TODO: first purchase warning for non premium users
    }

    if (price && justAcquired && !hasProviderSubscription) {
      return <PurchaseNotice price={price / 100} itemUrl={createItemUriFromManifest(manifest)} />;
    }

    return null;
  };

  render() {
    return (
      <AltContainer
        stores={{
          itemState: ItemStore,
          tilesState: TilesStore,
          authState: AuthStore,
        }}
        render={this._renderPurchaseNotice}
      />
    );
  }
}

export default withItemNotifications(PurchaseNoticeContainer);
