// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dialogBody-3yEhw{padding-bottom:27px}.title-3SEGq{font-family:\"GT-Walsheim\",sans-serif;color:#000;font-size:22px;line-height:26px;letter-spacing:-0.03em;margin-top:2px;margin-bottom:5px}.paragraph-2-kmn{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-size:16px;line-height:22px;letter-spacing:-0.02em}.buyCreditsCta-2sI0_{font-weight:500;font-size:16px;letter-spacing:-0.02em;padding:11px 20px;width:100%}.closeCta-2s3E3{font-family:\"GT-Walsheim\",sans-serif;font-weight:500;font-size:16px;letter-spacing:-0.02em;color:#7f7f7f;margin-top:11px;cursor:pointer}\n", ""]);
// Exports
exports.locals = {
	"dialogBody": "dialogBody-3yEhw",
	"title": "title-3SEGq",
	"paragraph": "paragraph-2-kmn",
	"buyCreditsCta": "buyCreditsCta-2sI0_",
	"closeCta": "closeCta-2s3E3"
};
module.exports = exports;
