import altConnect from 'higher-order-components/altConnect';
import ItemStore from 'stores/ItemStore';
import { getTileManifest } from 'selectors/tiles';
import TilesStore from 'stores/TilesStore';
import ItemMeta from '../components/ItemMeta';

function mapStateToProps({ itemState, tilesState }) {
  const { selectedItemId } = itemState;
  const tile = tilesState.tiles.get(selectedItemId);

  if (!tile) {
    return null;
  }

  const manifest = getTileManifest(tile);

  return {
    date: manifest.date,
    length: manifest.length,
  };
}

mapStateToProps.stores = {
  ItemStore,
  TilesStore,
};

export default altConnect(mapStateToProps)(ItemMeta);
