import React, { PureComponent } from 'react';
import { pipe, prop, equals } from 'ramda';
import ItemStore from 'stores/ItemStore';
import TilesStore from 'stores/TilesStore';
import NotificationsActions from 'actions/NotificationsActions';
import ItemNotFreeForPremiumUserNotification from 'components/notifications/ItemNotFreeForPremiumUserNotification';
import { history } from 'byebye';
import {
  FEATURE_CAPABILITY_MICROPAYMENTS,
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
} from 'app-constants';
import { hasCapability } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';

const isMoneyAcquisition = pipe(prop('purchase_origin'), equals('money'));

const isPremiumPlusMicropaymentOrCreditsUser = capabilitiesState => {
  const hasMicropayments = hasCapability(capabilitiesState, FEATURE_CAPABILITY_MICROPAYMENTS);
  const hasPremiumCredits = hasCapability(
    capabilitiesState,
    FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  );
  const hasPremium = hasCapability(capabilitiesState, FEATURE_CAPABILITY_BLENDLE_PREMIUM);

  return (hasPremium && hasMicropayments) || hasPremiumCredits;
};

export default ComposedComponent =>
  class withItemNotifications extends PureComponent {
    notificationShownId = null;

    notificationTimeout = null;

    componentDidMount() {
      ItemStore.listen(this.shouldShowNotification);
      TilesStore.listen(this.shouldShowNotification);
    }

    componentWillUnmount() {
      ItemStore.unlisten(this.shouldShowNotification);
      TilesStore.unlisten(this.shouldShowNotification);
      clearTimeout(this.notificationTimeout);
    }

    shouldShowNotification = () => {
      const capabilitiesState = FeatureCapabilitiesStore.getState();
      const { tiles } = TilesStore.getState();
      const { selectedItemId, justAcquired, acquisition } = ItemStore.getState();
      const tile = tiles.get(selectedItemId);

      if (!isPremiumPlusMicropaymentOrCreditsUser(capabilitiesState)) {
        return;
      }

      if (!tile) {
        return;
      }

      // Only show the notification once per itemId
      if (this.notificationShownId === selectedItemId) {
        return;
      }

      // Do not show the notification if the item has been bought in the past
      if (!justAcquired) {
        return;
      }

      if (!isMoneyAcquisition(acquisition)) {
        return;
      }

      this.showItemNotFreeForPremiumUserNotification(tile, selectedItemId);
    };

    showItemNotFreeForPremiumUserNotification = (tile, id) => {
      this.notificationShownId = id;
      const notificationId = `item-bundle-notification-${id}`;

      const notificationProps = {
        price: tile.price,
        onClick: () => {
          NotificationsActions.hideNotification(notificationId);
          history.navigate(`${window.location.pathname}/refund`, {
            trigger: true,
          });
        },
      };

      this.notificationTimeout = setTimeout(() => {
        NotificationsActions.showNotification(
          ItemNotFreeForPremiumUserNotification,
          notificationProps,
          notificationId,
        );
      }, 2000);
    };

    render() {
      return <ComposedComponent {...this.props} />;
    }
  };
