// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".notification-2FhwM{text-align:center}@media screen and (min-width: 600px){.notification-2FhwM{text-align:left}}@media screen and (max-width: 600px){.dismissButton-2E7xX{color:#fff;border-color:#fff}}\n", ""]);
// Exports
exports.locals = {
	"notification": "notification-2FhwM",
	"dismissButton": "dismissButton-2E7xX"
};
module.exports = exports;
