// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".agree-3HHCa{margin-right:10px}.pinButton-GanPI{margin-right:10px;line-height:19px}.pinButton-GanPI:before{font-family:'blendle-icons';speak:none;font-style:normal;font-weight:normal;font-variant:normal;text-transform:none;text-indent:0;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"\\EA09\";padding-right:9px;transition:color 0.15s ease;font-size:16px;display:inline-block;vertical-align:bottom}.confirm-1BXQB{line-height:19px}\n", ""]);
// Exports
exports.locals = {
	"agree": "agree-3HHCa",
	"pinButton": "pinButton-GanPI",
	"confirm": "confirm-1BXQB"
};
module.exports = exports;
