import {
  STATUS_INITIAL,
  STATUS_PENDING,
  STATUS_OK,
  STATUS_ERROR,
} from 'app-constants';
import alt from 'instances/altInstance';
import ShareForFreeActions from '../actions/ShareForFreeActions';

class ShareForFreeStore {
  constructor() {
    this.bindActions(ShareForFreeActions);

    this.state = {
      status: STATUS_INITIAL,
      shareToken: null,
      error: null,
    };
  }

  onGenerateShareToken() {
    this.setState({
      status: STATUS_PENDING,
      shareToken: null,
      error: null,
    });
  }

  onGenerateShareTokenSuccess(shareToken) {
    this.setState({
      status: STATUS_OK,
      shareToken,
      error: null,
    });
  }

  onGenerateShareTokenError(error) {
    this.setState({
      status: STATUS_ERROR,
      shareToken: null,
      error,
    });
  }
}

export default alt.createStore(ShareForFreeStore, 'ShareForFreeStore');
