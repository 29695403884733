import React from 'react';
import { string } from 'prop-types';
import { Columns } from '@blendle/lego';
import Link from 'components/Link';
import IconAudio from 'components/icons/Audio';
import styledModule from 'helpers/styledModule';
import { getAudioDeeplinkLandingUrl } from 'helpers/audioDeeplinkUrl';
import CSS from './style.scss';

const StyledAudioBanner = styledModule.div(CSS.audioBanner);
const Content = styledModule(Columns)(CSS.content);
const IconWrapper = styledModule.div(CSS.iconWrapper);
const Text = styledModule.p(CSS.text);

export default function AudioBanner({ audioTrackId, className }) {
  return (
    <StyledAudioBanner className={className}>
      <Content>
        <IconWrapper>
          <IconAudio />
        </IconWrapper>
        <Text>
          In de Blendle-app kun je dit artikel (en een heleboel andere stukken){' '}
          <Link href={getAudioDeeplinkLandingUrl(audioTrackId)}>beluisteren</Link>.
        </Text>
      </Content>
    </StyledAudioBanner>
  );
}

AudioBanner.propTypes = {
  audioTrackId: string.isRequired,
  // eslint-disable-next-line react/require-default-props
  className: string,
};
