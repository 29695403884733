// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toggleButton-1ZmDi{color:#948784;background:none;width:55px;height:55px;padding:0;cursor:pointer}@media screen and (min-width: 600px){.toggleButton-1ZmDi:hover{color:#333}}.arrow-3GSE2{width:14px;height:14px;transition:transform 75ms ease-out}.open-2Q5GW .arrow-3GSE2{transform:rotate(180deg)}.dropdownOptions-SnOMB{width:250px;margin-top:-10px}.dropdownOptions-SnOMB>div{padding-bottom:0}.dropdownItem-2qyCc{font-family:inherit;display:flex;align-items:center;width:100%;font-size:1em;cursor:pointer;height:35px;padding:0 11px;color:#948784;text-decoration:none}.dropdownItem-2qyCc:before{display:flex;height:100%;align-items:center;font-size:18px;padding-right:10px}.dropdownItem-2qyCc:hover{color:#333}.dropdownItem-2qyCc:hover.lnkEditor-qObCh{color:#fed141}.iconWrapper-P5KC2{width:22px;margin-right:10px;display:flex}\n", ""]);
// Exports
exports.locals = {
	"toggleButton": "toggleButton-1ZmDi",
	"arrow": "arrow-3GSE2",
	"open": "open-2Q5GW",
	"dropdownOptions": "dropdownOptions-SnOMB",
	"dropdownItem": "dropdownItem-2qyCc",
	"lnkEditor": "lnkEditor-qObCh",
	"iconWrapper": "iconWrapper-P5KC2"
};
module.exports = exports;
