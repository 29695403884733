import React, { PureComponent } from 'react';
import AltContainer from 'alt-container';
import TilesStore from 'stores/TilesStore';
import ItemStore from 'stores/ItemStore';
import Share from '../components/Share';

class ShareContainer extends PureComponent {
  renderShare = ({ tilesState, itemState }) => {
    const { tiles } = tilesState;
    const { selectedItemId } = itemState;
    const tile = tiles.get(selectedItemId);

    if (!tile) {
      return null;
    }

    return <Share />;
  };

  render() {
    return (
      <AltContainer
        stores={{
          itemState: ItemStore,
          tilesState: TilesStore,
        }}
        render={this.renderShare}
      />
    );
  }
}

export default ShareContainer;
