// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".closeButton-20NQS{width:44px;height:55px;padding:0;background:#fff;color:#948784;vertical-align:top;cursor:pointer}.closeButton-20NQS svg{width:14px;height:14px}.closeButton-20NQS:hover svg path{color:#333;fill:#333}\n", ""]);
// Exports
exports.locals = {
	"closeButton": "closeButton-20NQS"
};
module.exports = exports;
