import { Component } from 'react';
import { string, number, bool } from 'prop-types';
import { compose, branch } from '@blendle/recompose';
import altConnect from 'higher-order-components/altConnect';
import redirect from 'higher-order-components/redirect';
import { isItemAcquired } from 'selectors/item';
import { replaceLastPath } from 'helpers/url';
import ItemActions from 'actions/ItemActions';
import ItemStore from 'stores/ItemStore';
import AuthStore from 'stores/AuthStore';
import withItemErrorHandling from '../higher-order-components/withItemErrorHandling';
import { getSocialOrigin } from '../helpers/socialOrigin';

class AcquireItem extends Component {
  static propTypes = {
    itemId: string.isRequired,
    userId: string.isRequired,
    socialOrigin: string,
    runningAcquisitionXHRs: number.isRequired,
    isAcquired: bool,
  };

  componentDidMount() {
    const { itemId, userId, socialOrigin, runningAcquisitionXHRs, isAcquired } = this.props;

    // check runningAcquisitionXHRs <= 0
    if (runningAcquisitionXHRs < 1 && !isAcquired) {
      ItemActions.acquireItem.defer(itemId, userId, socialOrigin);
    }
  }

  render() {
    return null; // No need to render anything, this component only performs some actions
  }
}

function mapStateToProps({ itemState, authState }, { params, location }) {
  const { itemId } = params;
  const { sharer } = location.query;
  const {
    user: { id: userId },
  } = authState;
  const { runningAcquisitionXHRs } = itemState;

  const isAcquired = isItemAcquired(itemState);

  const redirectTo =
    isAcquired && location.pathname.endsWith('/acquire')
      ? `${replaceLastPath(location.pathname, '')}${location.search}`
      : '';

  return {
    itemId,
    userId,
    socialOrigin: getSocialOrigin(itemId, sharer),
    redirectTo,
    runningAcquisitionXHRs,
    isAcquired,
  };
}
mapStateToProps.stores = { ItemStore, AuthStore };

export default compose(
  altConnect(mapStateToProps),
  branch(({ redirectTo }) => redirectTo, redirect({ replace: true })),
  withItemErrorHandling,
)(AcquireItem);
