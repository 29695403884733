// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".entities-22Af8{margin-bottom:50px;padding-left:15px;padding-right:15px;max-width:670px;margin-left:auto;margin-right:auto}@media print{.entities-22Af8{display:none}}\n", ""]);
// Exports
exports.locals = {
	"entities": "entities-22Af8"
};
module.exports = exports;
