/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ShareToEmailContainer from 'containers/ShareToEmailContainer';
import withHideOnScroll from '../../higher-order-components/withHideOnScroll';
import CSS from './StickySharing.scss';

const shareAnalytics = { location_in_layout: 'sticky-sharing-bottom-bar' };

const StickySharing = ({
  onSocialShare,
  onToggleBlendleShare,
  itemId,
  blendleButtonActive,
  blendleButtonLoading,
  isHiddenByScroll,
  socialPlatforms,
}) => {
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);

  const onClickEmailButton = (e) => {
    e.preventDefault();
    setIsEmailDialogOpen(true);
  };

  const onEmailShare = () => {
    setIsEmailDialogOpen(false);
  };

  const createSocialShare = (platform) => {
    if (platform === 'email') {
      return onClickEmailButton;
    }
    if (platform === 'blendle') {
      return onToggleBlendleShare;
    }
    return () => onSocialShare(platform);
  };

  const renderButtons = () => {
    return socialPlatforms.map((platform) => {
      const platformCapitalized = platform.charAt(0).toUpperCase() + platform.slice(1);

      return (
        <div
          className={classNames(CSS.icon, CSS[`icon${platformCapitalized}`], {
            [CSS.iconBlendleLiked]: platform === 'blendle' && blendleButtonActive,
            [CSS.iconLoading]: platform === 'blendle' && blendleButtonLoading,
          })}
          key={platform}
          onClick={createSocialShare(platform)}
        />
      );
    });
  };

  const containerClassNames = classNames(CSS.container, {
    [CSS.isHidden]: isHiddenByScroll,
  });

  return (
    <div className={containerClassNames}>
      <div className={CSS.stickySharing}>
        <div className={CSS.buttons}>
          {renderButtons()}
          <ShareToEmailContainer
            itemId={itemId}
            analytics={shareAnalytics}
            isVisible={isEmailDialogOpen}
            onClose={onEmailShare}
          />
        </div>
      </div>
    </div>
  );
};

StickySharing.propTypes = {
  onSocialShare: PropTypes.func.isRequired,
  onToggleBlendleShare: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  blendleButtonActive: PropTypes.bool.isRequired,
  blendleButtonLoading: PropTypes.bool.isRequired,
  isHiddenByScroll: PropTypes.bool.isRequired,
  socialPlatforms: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default withHideOnScroll(StickySharing);
