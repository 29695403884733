import { compose, branch, withProps, renderNothing } from '@blendle/recompose';
import { path } from 'ramda';

import altConnect from 'higher-order-components/altConnect';
import redirect from 'higher-order-components/redirect';
import { replaceLastPath } from 'helpers/url';
import Analytics from 'instances/analytics';
import PaymentActions from 'actions/PaymentActions';
import AuthStore from 'stores/AuthStore';
import ItemStore from 'stores/ItemStore';
import TilesStore from 'stores/TilesStore';
import { getTileManifest } from 'selectors/tiles';
import {
  FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  INTERNAL_LOCATION,
} from 'app-constants';
import { hasCapability } from 'selectors/capabilities';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { convertFromCents } from 'helpers/formatCurrency';
import { getBuyCreditsPageUrl } from 'helpers/paymentPageUrl/getBuyCreditsPageUrl';
import BuyItemWarningContainer, { BUY_ITEM_WARNING_VERSION } from './container';
import { getAcquireItemPath } from './helpers';

function mapStateToProps({ authState, itemState, tilesState, featureCapabilitiesState }, props) {
  const {
    balanceTooLowItemIds,
    params: { itemId },
    location,
  } = props;
  const tile = tilesState.tiles.get(itemId);

  if (!tile) {
    return { isHidden: true };
  }

  const { user } = authState;
  const { returnUrl } = itemState;
  const { price, requires_payment_confirmation: requiresPaymentConfirmation } = tile;

  const acquireItemPath = getAcquireItemPath(location);
  const balance = parseFloat(user.get('balance'));
  const itemPrice = convertFromCents(price);
  const isUserConfirmed = user.get('email_confirmed');

  const hasCreditsCapability = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_HAS_PREMIUM_CREDITS,
  );
  const hasBlendlePremium = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_BLENDLE_PREMIUM,
  );

  const redirectWhenOutOfBalance = (pathname) => {
    const successUrl = replaceLastPath(location.pathname, '');
    PaymentActions.setReturnUrl.defer(successUrl);

    const redirectTo = {
      state: {
        returnUrl,
        successUrl,
      },
      pathname,
    };

    return { redirectTo };
  };

  if (user.isFreeloader()) {
    return { redirectTo: acquireItemPath };
  }

  // If the user is not yet confirmed, redirect them to the acquireItemPath,
  // there the error handling will render the `ConfirmAccount` dialog
  if (!isUserConfirmed) {
    return { redirectTo: acquireItemPath };
  }

  if (!requiresPaymentConfirmation) {
    if (hasBlendlePremium) {
      return { redirectTo: acquireItemPath };
    }

    if (balance < itemPrice) {
      if (!balanceTooLowItemIds.has(itemId)) {
        Analytics.track('Balance too low');
        balanceTooLowItemIds.add(itemId);
      }

      return redirectWhenOutOfBalance('/payment');
    }

    return { redirectTo: acquireItemPath };
  }

  const confirmationDialogProps = {
    balance,
    itemPrice,
    acquireItemPath,
    itemId,
  };

  if (!hasCreditsCapability) {
    if (balance < itemPrice) {
      return redirectWhenOutOfBalance('/payment/outofbalance');
    }

    return {
      version: BUY_ITEM_WARNING_VERSION.PREMIUM_AND_MICROPAYMENTS,
      ...confirmationDialogProps,
    };
  }

  return {
    version: BUY_ITEM_WARNING_VERSION.CREDITS,
    buyCreditsPath: getBuyCreditsPageUrl(INTERNAL_LOCATION.ITEM, {
      itemId,
    }),
    providerId: path(['provider', 'id'], getTileManifest(tile)),
    ...confirmationDialogProps,
  };
}

mapStateToProps.stores = {
  AuthStore,
  TilesStore,
  ItemStore,
  FeatureCapabilitiesStore,
};

export default compose(
  withProps({ balanceTooLowItemIds: new Set() }), // track IDs to only send events once
  altConnect(mapStateToProps),
  branch(({ isHidden }) => isHidden, renderNothing),
  branch(({ redirectTo }) => !!redirectTo, redirect({ replace: true, renderNothing: true })),
)(BuyItemWarningContainer);
