import React from 'react';
import { number, string, oneOf, shape, func } from 'prop-types';

import { withRouter } from 'react-router';
import { hasPath, path } from 'ramda';

import Analytics from 'instances/analytics';
import useEventOnMount from 'hooks/useEventOnMount';
import { useProviderStyles } from 'hooks/useProviderStyles';
import CreditsBuyItemWarning from '../../components/CreditsBuyItemWarning';
import BuyItemWarningDialog from '../../components/BuyItemWarningDialog';
import { useInternalLocation } from './helpers';

export const BUY_ITEM_WARNING_VERSION = {
  CREDITS: 'credits',
  PREMIUM_AND_MICROPAYMENTS: 'premium_and_micropayments',
};

const numberOfCredits = path(['query', 'numberOfCredits']);
const didCreditsTopup = hasPath(['query', 'didCreditsTopup']);

export const Container = ({
  version,
  itemId,
  acquireItemPath,
  router,
  location,
  providerId,
  ...rest
}) => {
  const internalLocation = useInternalLocation();
  const { bgColor, fontColor } = useProviderStyles(providerId);

  useEventOnMount('Payment Needed Button Shown', {
    item_id: itemId,
    internal_location: internalLocation,
  });

  const onAcquire = () => {
    Analytics.track('Payment Needed Button Pressed', {
      item_id: itemId,
      internal_location: internalLocation,
    });

    router.replace(acquireItemPath);
  };

  const onShowOutOfBalanceDialog = () => {
    Analytics.track('Insufficient Balance Alert Shown', {
      item_id: itemId,
      internal_location: internalLocation,
    });
  };

  const onDismissOutOfBalanceDialog = () => {
    Analytics.track('Insufficient Balance Alert Dismissed', {
      item_id: itemId,
      internal_location: internalLocation,
    });
  };

  switch (version) {
    case BUY_ITEM_WARNING_VERSION.CREDITS:
      return (
        <CreditsBuyItemWarning
          data-testid="credits-buy-item-warning"
          onAcquire={onAcquire}
          onShowOutOfBalanceDialog={onShowOutOfBalanceDialog}
          onDismissOutOfBalanceDialog={onDismissOutOfBalanceDialog}
          didCreditsTopup={didCreditsTopup(location)}
          numberOfCredits={numberOfCredits(location)}
          itemId={itemId}
          backgroundColor={bgColor}
          fontColor={fontColor}
          {...rest}
        />
      );
    case BUY_ITEM_WARNING_VERSION.PREMIUM_AND_MICROPAYMENTS:
      return (
        <BuyItemWarningDialog
          data-testid="premium-and-micropayments-buy-item-warning"
          onAcquire={onAcquire}
          {...rest}
        />
      );
    default:
      throw new Error(`Received unknown dialog version: ${version}`);
  }
};

Container.propTypes = {
  version: oneOf([
    BUY_ITEM_WARNING_VERSION.CREDITS,
    BUY_ITEM_WARNING_VERSION.PREMIUM_AND_MICROPAYMENTS,
  ]).isRequired,
  balance: number.isRequired,
  itemPrice: number.isRequired,
  acquireItemPath: string.isRequired,
  buyCreditsPath: string,
  itemId: string.isRequired,
  router: shape({
    replace: func.isRequired,
  }).isRequired,
  location: shape({
    query: {
      didCreditsTopup: string,
      numberOfCredits: string,
    }.isRequired,
  }).isRequired,
};

Container.defaultProps = {
  buyCreditsPath: undefined,
  location: {
    query: {
      didCreditsTopup: undefined,
      numberOfCredits: undefined,
    },
  },
};

export default withRouter(Container);
