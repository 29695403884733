/* eslint-disable class-methods-use-this */

import alt from 'instances/altInstance';
import R from 'ramda';
import * as SharingManager from 'managers/sharing';

const getToken = R.path(['data', 'token']);

class ShareForFreeActions {
  generateShareTokenSuccess = token => token;

  generateShareTokenError = error => error;

  generateShareToken(itemId) {
    SharingManager.generateShareToken(itemId)
      .then(res => this.generateShareTokenSuccess(getToken(res)))
      .catch(error => this.generateShareTokenError(error));

    return { itemId };
  }
}

export default alt.createActions(ShareForFreeActions);
