import React from 'react';
import { string } from 'prop-types';
import Analytics from 'instances/analytics';
import BrowserEnv from 'instances/browser_environment';
import styledModule from 'helpers/styledModule';
import { Button } from '@blendle/lego';
import CSS from './style.scss';

const Wrapper = styledModule.div(CSS.wrapper);
const Container = styledModule.div(CSS.container);
const Title = styledModule('h4')(CSS.title);
const Text = styledModule.p(CSS.text);
const InputWrapper = styledModule.div(CSS.inputWrapper);
const CopyButton = styledModule(Button)(CSS.button);

const baseShareUrl = `${window.location.origin}/shared/`;

function trackEvent(itemId) {
  Analytics.track('Share Free Article: Link Copied', {
    item_id: itemId,
  });
}

class ShareForFree extends React.Component {
  static propTypes = {
    shareToken: string.isRequired,
    itemId: string.isRequired,
  };

  state = {
    isCopied: false,
  };

  getButtonText() {
    const { isCopied } = this.state;

    if (BrowserEnv.isIOS()) {
      return 'Selecteer linkje';
    }

    if (isCopied) {
      return 'Gekopieerd';
    }

    return 'Kopieer linkje';
  }

  copyToClipboard = e => {
    const { itemId } = this.props;

    if (BrowserEnv.isIOS()) {
      const range = document.createRange();
      range.selectNodeContents(this.input);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      this.input.setSelectionRange(0, 999999);

      document.execCommand('copy');
    } else {
      this.input.select();
      document.execCommand('copy');
      e.target.focus();
      this.setState({ isCopied: true });
    }

    trackEvent(itemId);
  };

  render() {
    const { isCopied } = this.state;
    const { shareToken } = this.props;

    const buttonColor = isCopied ? 'cash-green' : 'razzmatazz';

    return (
      <Wrapper>
        <Container className={isCopied ? CSS.green : CSS.red}>
          <Title>Deel gratis met een vriend!</Title>
          <Text>
            Als je dit artikel deelt met een vriend zonder Blendle, kan die het gratis lezen. Spread
            the love.
          </Text>
          <InputWrapper>
            <input
              ref={input => {
                this.input = input;
              }}
              value={`${baseShareUrl}${shareToken}`}
              className={CSS.input}
              readOnly
            />
            <CopyButton color={buttonColor} onClick={this.copyToClipboard}>
              {this.getButtonText()}
            </CopyButton>
          </InputWrapper>
        </Container>
      </Wrapper>
    );
  }
}

export default ShareForFree;
