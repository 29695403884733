// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".providerLogo-21m-7{height:35px}\n", ""]);
// Exports
exports.locals = {
	"providerLogo": "providerLogo-21m-7"
};
module.exports = exports;
