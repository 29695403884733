// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3Bf-Z{display:flex;align-items:center;justify-content:center;flex-basis:auto}.l-desktop .whatsapp-2DHVE{display:none}@media screen and (max-width: 600px){.linkedin-1R9oh{display:none}}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3Bf-Z",
	"whatsapp": "whatsapp-2DHVE",
	"linkedin": "linkedin-1R9oh"
};
module.exports = exports;
