// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".title-2iCBk{font-family:\"GT-Walsheim\",sans-serif;color:#000;font-size:22px;line-height:26px;letter-spacing:-0.03em;margin-top:1px;margin-bottom:4px}.paragraph-3UI0g{font-family:\"GT-Walsheim\",sans-serif;color:#333;font-size:16px;line-height:22px;letter-spacing:-0.02em}.cta-3DGym{font-weight:500;font-size:16px;letter-spacing:-0.02em;padding:11px 20px;width:100%}\n", ""]);
// Exports
exports.locals = {
	"title": "title-2iCBk",
	"paragraph": "paragraph-3UI0g",
	"cta": "cta-3DGym"
};
module.exports = exports;
