// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3PHF-{display:flex;margin-top:45px;margin-bottom:45px}@media screen and (min-width: 600px){.container-3PHF-{margin-left:5%;margin-right:5%}}.video-2KJpR{margin:0 auto}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3PHF-",
	"video": "video-2KJpR"
};
module.exports = exports;
