// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-2jHAe{display:flex;justify-content:center;align-items:center;height:100vh}.body-2gehh{padding:20px;width:500px;max-width:100%}.body-2gehh .your-email{font-weight:bold;color:#333}.body-2gehh a{text-decoration:none}.body-2gehh .lnk-block{position:relative;display:block;font-weight:bold;padding-left:16px}.body-2gehh .lnk-block:before{content:'';position:absolute;left:0;top:4px;width:0;height:0;border:4px solid transparent;border-left:6px solid #ff6255}.copy-3tZVG{color:#948784;padding:20px 0;font-size:16px}.confirm-2rHNt{display:block;margin-top:2em;width:170px}\n", ""]);
// Exports
exports.locals = {
	"container": "container-2jHAe",
	"body": "body-2gehh",
	"copy": "copy-3tZVG",
	"confirm": "confirm-2rHNt"
};
module.exports = exports;
