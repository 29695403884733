import React from 'react';
import SharingButtonsContainer from '../../containers/SharingButtonsContainer';
import RecommendButtonContainer from '../../containers/RecommendButtonContainer';
import CSS from './Share.scss';

function Share() {
  return (
    <div className={CSS.share}>
      <RecommendButtonContainer />
      <SharingButtonsContainer />
    </div>
  );
}

export default Share;
