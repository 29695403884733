import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import formatCurrency from 'helpers/formatCurrency';
import { translateElement } from 'instances/i18n';
import CSS from './style.scss';

function PurchaseNotice(props) {
  const href = `/${props.itemUrl}/refund`;

  return (
    <Link href={href} className={CSS.purchaseNotice} tabIndex={0}>
      <strong>{formatCurrency(props.price)}</strong>
      {translateElement('item.text.purchase_notice')}
    </Link>
  );
}

PurchaseNotice.propTypes = {
  price: PropTypes.number.isRequired,
  itemUrl: PropTypes.string.isRequired,
};

export default PurchaseNotice;
