import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure, withHandlers, setPropTypes } from '@blendle/recompose';
import classNames from 'classnames';
import SharingButton from 'components/SharingButton';
import useCookieConsent from 'hooks/useCookieConsent';
import { SHARING_PLATFORM } from 'app-constants';
import withShareToEmail from '../../higher-order-components/withShareToEmail';
import CSS from './style.scss';

const enhance = compose(
  setPropTypes({
    itemId: PropTypes.string.isRequired,
    onSocialShare: PropTypes.func.isRequired,
    analytics: PropTypes.object,
  }),
  withShareToEmail,
  withHandlers({
    onShare: (props) => (platform, event) => {
      event.preventDefault();

      if (platform === 'email') {
        props.openEmailShareDialog();
      } else {
        props.onSocialShare(platform);
      }
    },
  }),
  pure,
);

const SharingButtons = enhance(({ className, onShare }) => {
  const { hasGivenConsent } = useCookieConsent();

  const socialPlatforms = [
    hasGivenConsent ? SHARING_PLATFORM.FACEBOOK : undefined,
    SHARING_PLATFORM.TWITTER,
    SHARING_PLATFORM.WHATSAPP,
    SHARING_PLATFORM.EMAIL,
    SHARING_PLATFORM.LINKEDIN,
  ].filter(Boolean);

  return (
    <div className={classNames(CSS.container, className)}>
      {socialPlatforms.map((platform) => (
        <SharingButton
          key={platform}
          onClick={onShare}
          platform={platform}
          className={CSS[platform]}
        />
      ))}
    </div>
  );
});

export default SharingButtons;
