// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".backdrop-P9ue6{background-image:linear-gradient(-225deg, #c931ea 0%, #ab7dff 100%) !important;height:100px}.backdropInner-2vg9V{transform:rotate(30deg);background-image:linear-gradient(44deg, #fca400 0%, #fecd43 100%) !important;width:200px;height:100px;right:-100px;top:0}\n", ""]);
// Exports
exports.locals = {
	"backdrop": "backdrop-P9ue6",
	"backdropInner": "backdropInner-2vg9V"
};
module.exports = exports;
