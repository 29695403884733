import React, { useEffect } from 'react';
import { string, arrayOf, shape, bool } from 'prop-types';
import { compose, onlyUpdateForKeys } from '@blendle/recompose';
import renderNothingIfIsHidden from 'higher-order-components/renderNothingIfIsHidden';
import { STATUS_ERROR, FEATURE_CAPABILITY_HAS_ALGORITHMIC_SELECTION } from 'app-constants';
import { getItemFromTiles } from 'selectors/tiles';
import altConnect from 'higher-order-components/altConnect';
import AuthStore from 'stores/AuthStore';
import TilesStore from 'stores/TilesStore';
import FeatureCapabilitiesStore from 'stores/FeatureCapabilitiesStore';
import { hasCapability } from 'selectors/capabilities';
import ReadMoreActions from '../actions/ReadMoreActions';
import ReadMoreStore from '../stores/ReadMoreStore';
import SuggestionSections from '../components/SuggestionSections';

const SuggestionSectionsContainer = ({ sections, itemId, areProviderCardsAllowed }) => {
  useEffect(() => {
    const userId = AuthStore.getState().user.id;
    ReadMoreActions.fetchReadMore.defer(userId, itemId);
  }, [itemId]);

  return (
    <SuggestionSections sections={sections} areProviderCardsAllowed={areProviderCardsAllowed} />
  );
};

SuggestionSectionsContainer.propTypes = {
  itemId: string.isRequired,
  sections: arrayOf(
    shape({
      id: string.isRequired,
      type: string.isRequired,
      label: string.isRequired,
      personalized: bool.isRequired,
      itemIds: arrayOf(string).isRequired,
      isLoading: bool,
    }),
  ),
  areProviderCardsAllowed: bool.isRequired,
};

SuggestionSectionsContainer.defaultProps = {
  sections: [],
};

function isLoadingSection(itemIds, tilesState) {
  return itemIds.some((itemId) => {
    const tile = tilesState.tiles.get(itemId);

    return !tile;
  });
}

export function mapStateToProps(
  { readMoreState, tilesState, featureCapabilitiesState },
  { itemId },
) {
  const tile = getItemFromTiles(itemId, tilesState);

  const areProviderCardsAllowed = hasCapability(
    featureCapabilitiesState,
    FEATURE_CAPABILITY_HAS_ALGORITHMIC_SELECTION,
  );

  const sections = readMoreState.sectionIds.map((sectionId) => {
    const section = readMoreState.sections.get(sectionId);
    const { id, type, label, personalized, item_uids: itemIds = [] } = section;

    return {
      id,
      type,
      personalized,
      label,
      itemIds,
      isLoading: isLoadingSection(itemIds, tilesState),
    };
  });

  return {
    itemId,
    isHidden: !tile || readMoreState.status === STATUS_ERROR,
    sections,
    areProviderCardsAllowed,
  };
}

mapStateToProps.stores = {
  TilesStore,
  ReadMoreStore,
  FeatureCapabilitiesStore,
};

const enhance = compose(
  onlyUpdateForKeys(['itemId']),
  altConnect(mapStateToProps),
  renderNothingIfIsHidden,
);

export default enhance(SuggestionSectionsContainer);
