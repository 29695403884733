import React from 'react';
import { translateElement, translate } from 'instances/i18n';
import Analytics from 'instances/analytics';

export default class Deeplink extends React.Component {
  componentDidMount() {
    Analytics.track('Deeplink 404');
  }

  render() {
    return (
      <div className="v-deeplink-error">
        <h2 className="title">{translate('deeplink.error.title')}</h2>
        <p>{translateElement('deeplink.error.message', false)}</p>
      </div>
    );
  }
}
