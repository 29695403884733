import React from 'react';
import CSS from './style.scss';

export default () => (
  <svg className={CSS.container} width="100%" height="100%" viewBox="0 0 314 317">
    <g>
      <g className={CSS.backdrop}>
        <path
          d="M313.2,158.2 C312.7,208.3 301.4,250.7 274.5,277.3 C248.1,304.5 206.2,315.9
            156.6,316.4 C107,315.9 65.1,304.5 38.7,277.3 C11.8,250.7 0.5,208.3 0,158.2 C0.5,108.1
            11.8,65.7 38.7,39.1 C65.1,11.9 107,0.5 156.6,0 C206.2,0.5 248.1,11.9 274.5,39.1
            C301.3,65.7 312.7,108.1 313.2,158.2 L313.2,158.2 Z"
          fill="#ff1060"
        />
      </g>
      <g className={CSS.arrows} transform="translate(77.000000, 79.000000)" fill="#fff">
        <path
          d="M93,79.2 L93,79.2 C68.2,52.4 44,26.4 19.7,0.3 C10.8,7.3 8.7,9 0.3,15.5 C0.3,15.5
            21.8,51.5 32.7,68.7 C35.3,72.9 36.9,76.1 37.2,79.2 C37,82.3 35.4,85.5 32.7,89.7
            C21.9,106.9 0.3,142.9 0.3,142.9 C8.6,149.4 10.7,151.1 19.7,158.1 C44,132 68.2,106
            93,79.2 L93,79.2"
        />
        <path
          d="M179.1,79.2 L179.1,79.2 C152.5,50.5 132.2,28.7 105.8,0.3 C96.9,7.3 94.8,9
            86.4,15.5 C86.4,15.5 107.9,51.5 118.8,68.7 C121.4,72.9 123,76.1 123.3,79.2 C123.1,82.3
            121.5,85.5 118.8,89.7 C108,106.9 86.4,142.9 86.4,142.9 C94.7,149.4 96.8,151.1
            105.8,158.1 C130,132 154.2,106 179.1,79.2 L179.1,79.2"
        />
      </g>
    </g>
  </svg>
);
