import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogBody } from '@blendle/lego';
import { translate } from 'instances/i18n';

function ItemNotFound({ onDismiss }) {
  return (
    <Dialog onClose={onDismiss} data-testid="item-not-found">
      <DialogBody>
        <p>{translate('item.errors.not_found')}</p>
        <Button onClick={onDismiss}>{translate('item.buttons.close')}</Button>
      </DialogBody>
    </Dialog>
  );
}

ItemNotFound.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

export default ItemNotFound;
