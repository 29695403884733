// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container-3mIdN{max-width:600px;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;margin:0 auto;text-align:center;padding:10px;font-size:1.28571em}@media screen and (min-width: 1024px){.l-desktop-lxhQ6 .container-3mIdN{padding:40px 10px}}.crab-3ASyr{width:100%;max-width:250px;display:inline-block;margin:30px}.title-3o5PA{font-size:1.71429em;margin-bottom:10px}.subTitle-3Kq1t{color:#948784;margin-bottom:10px;font-size:20px}.body-3kJwo{line-height:1.28571em}.logo-c4JoC{margin:30px auto}\n", ""]);
// Exports
exports.locals = {
	"container": "container-3mIdN",
	"l-desktop": "l-desktop-lxhQ6",
	"crab": "crab-3ASyr",
	"title": "title-3o5PA",
	"subTitle": "subTitle-3Kq1t",
	"body": "body-3kJwo",
	"logo": "logo-c4JoC"
};
module.exports = exports;
