import React from 'react';
import { string, bool, func } from 'prop-types';
import { Chip } from '@blendle/lego';

function EntityChip({ label, isChecked, onClick }) {
  return (
    <Chip onClick={onClick} isChecked={isChecked}>
      {label}
    </Chip>
  );
}

EntityChip.propTypes = {
  label: string.isRequired,
  isChecked: bool.isRequired,
  onClick: func.isRequired,
};

export default EntityChip;
