import React from 'react';
import PropTypes from 'prop-types';
import elementToString from 'helpers/elementToString';

export default function DOMNodeComponent(props) {
  const { node, ...rest } = props;
  return <div {...rest} dangerouslySetInnerHTML={{ __html: elementToString(node) }} />;
}

DOMNodeComponent.propTypes = {
  node: PropTypes.object.isRequired,
};
