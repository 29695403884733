import React from 'react';
import { arrayOf, shape, bool, string } from 'prop-types';
import PageSection from 'modules/sectionsPage/components/PageSection';
import { MOBILE_LAYOUT_WRAPPING } from 'modules/sectionsPage/constants';
import SuggestionVisibleSensor from './SuggestionVisibleSensor';
import SuggestionSectionHeader from './SuggestionSectionHeader';
import CSS from './style.scss';

function SuggestionSections({ sections, areProviderCardsAllowed }) {
  return (
    <div className={CSS.suggestionSections}>
      {sections
        .filter(section => !section.isLoading && section.itemIds.length !== 0)
        .map(section => {
          const itemIds = section.itemIds.slice(0, 5);

          const { personalized, id, type, label, isLoading } = section;

          return (
            <SuggestionVisibleSensor key={`suggestion-${id}`} type={type} sectionId={id}>
              <div data-testid={`suggestions-${id}`}>
                <PageSection
                  itemIds={itemIds}
                  tilesCount={itemIds.length}
                  internalLocation="readmore/bundle"
                  sectionType={type}
                  sectionConsistsOfProviderCards={personalized}
                  areProviderCardsAllowed={areProviderCardsAllowed}
                  mobileLayout={MOBILE_LAYOUT_WRAPPING}
                  hideSectionAction
                  sectionId={id}
                  title={label}
                  headerComponent={SuggestionSectionHeader}
                  isLoading={isLoading}
                  data-testid="page-section"
                />
              </div>
            </SuggestionVisibleSensor>
          );
        })}
    </div>
  );
}

SuggestionSections.propTypes = {
  sections: arrayOf(
    shape({
      label: string.isRequired,
      isLoading: bool.isRequired,
      type: string.isRequired,
      personalized: bool.isRequired,
      itemIds: arrayOf(string).isRequired,
    }),
  ),
  areProviderCardsAllowed: bool.isRequired,
};

SuggestionSections.defaultProps = {
  sections: [],
};

export default SuggestionSections;
